import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { LocatorResult } from '../../../editor/core/highed.mapapi';
import { addLocationMapMarkerAction } from '../../../pages/ChartEditorPage/actions/locationMap';
import { startAppListening } from '../../listenerMiddleware';
import { revertLocationMapState } from '../../actions/locationMap';
import { RootState } from 'redux/store';

export type SearchState = {
  result: LocatorResult | undefined;
  resultOutsideView: boolean;
};

const initialState: SearchState = {
  result: undefined,
  resultOutsideView: false
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  extraReducers: (builder) => builder.addCase(revertLocationMapState, () => initialState),
  reducers: {
    setSearchResult(state, action: PayloadAction<{ result: LocatorResult }>) {
      state.result = {
        ...action.payload.result,
        bbox: [
          action.payload.result.bbox[2],
          action.payload.result.bbox[0],
          action.payload.result.bbox[3],
          action.payload.result.bbox[1]
        ]
      };
    },
    setResultOutsideView(state, action: PayloadAction<boolean>) {
      state.resultOutsideView = action.payload;
    }
  }
});

export const { setSearchResult, setResultOutsideView } = searchSlice.actions;
export const selectResultOutsideView = (state: RootState) => state.locationMapSearch.resultOutsideView;

startAppListening({
  actionCreator: setSearchResult,
  effect: (action, listenerApi) => {
    const displayName = action.payload.result.name.split(',');
    let labelText = '';
    switch (action.payload.result.type) {
      case 'house':
        labelText = `${displayName[1]} ${displayName[0]}`;
        break;
      default:
        labelText = displayName[0];
        break;
    }

    listenerApi.dispatch(
      addLocationMapMarkerAction({
        id: nanoid(8),
        label: labelText,
        location: [action.payload.result.lon, action.payload.result.lat],
        type: 'PinMarker'
      })
    );
  }
});

export default searchSlice.reducer;
