/* eslint-disable complexity */
import { cloneDeep } from 'lodash';
import { useCompanyThemeOptions } from 'pages/CompanyThemeEditorPage/middleware/themeEditorPage';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import templates from '../../../editor/core/highed.template.plugins';
import { merge } from 'editor/core/highcharts-editor';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { setAction as setChartAction } from '../actions/chartEditor';
import { parseCSV } from '../utils/chartEditorDataHelper';
import { getChartConfig } from '../selectors/chartEditor';
import allTemplates from '../meta/templates/Templates';
import { forceFontResize, updateAggregated } from './ChartEditor';
import { setData, setTemplateData } from './ChartEditorData';
import { isTilemap } from '../utils/chartEditorMapHelper';
import { selectDefaultTheme } from './ChartEditorThemes';
import { updateColorAxis } from '../utils/chartEditorThemesHelper';
import { defaultLocationMapCustomOptions, defaultChartOptions, defaultMapOptions } from '../meta/DefaultOptions';
import { getMarkerPresets } from './LocationMap/LocationMap';
const defaultMap = 'countries/us/us-all';

function getTemplateType(aggregatedOptions) {
  if (aggregatedOptions?.data) {
    if (aggregatedOptions.data.csv) return 'csv';
    if (aggregatedOptions.data.googleSpreadsheetKey) return 'google';
    if (aggregatedOptions.data.text) return 'text';
    if (aggregatedOptions.data.csvURL || aggregatedOptions.data.rowsURL || aggregatedOptions.data.columnsURL) {
      return 'live';
    }
  }

  return 'csv';
}

function getDataOptions(aggregatedOptions, dataOptions, dataType) {
  if (dataType === 'csv') {
    return cloneDeep(dataOptions);
  } else if (dataType === 'google' || dataType === 'live') {
    return cloneDeep(aggregatedOptions?.data ?? {});
  }

  return cloneDeep(aggregatedOptions?.data ?? {});
}

function getDataProperties(aggregatedOptions, dataOptions) {
  const dataType = getTemplateType(aggregatedOptions);
  return {
    dataType,
    dataOptions: getDataOptions(aggregatedOptions, dataOptions, dataType)
  };
}

function createDropdownValues(chartConfig, isNowTilemap, customizedOptions) {
  const wasTilemap = isTilemap(chartConfig.chosenWizardTemplate);
  let dropdownValues = {};

  // Sort out dropdown values
  if (isNowTilemap && !wasTilemap) {
    // Is now a tilemap coming from a normal map
    dropdownValues = {
      countryValue: {
        label: 'United States of America',
        value: 'honeycomb-us'
      }
    };
    delete customizedOptions.chart.map;
  } else if (!isNowTilemap && wasTilemap) {
    // Is now a normal map coming from a tilemap
    dropdownValues = {
      countryValue: {
        label: 'United States of America',
        value: `${defaultMap}.js`
      },
      categoryValue: {
        label: 'Standard',
        value: `${defaultMap}.js`
      }
    };
    if (!customizedOptions.chart) customizedOptions.chart = {};
    customizedOptions.chart.map = defaultMap;
  }

  return dropdownValues;
}

function getDefaultOptions(provider, isMap) {
  let customizedOptions = {};
  if (provider === 'locationMap') {
    customizedOptions = merge({}, defaultLocationMapCustomOptions);
  } else {
    let defaultOptions = merge({}, defaultChartOptions);
    if (isMap) defaultOptions = merge(defaultOptions, defaultMapOptions);
    customizedOptions = merge(customizedOptions, merge({}, defaultOptions));
  }

  return customizedOptions;
}

function* setDefaultThemeFromTemplate(changedProvider, isLocationMap) {
  const { options: companyThemeOptions } = yield select((state) => state.companyThemeEditorPage);

  if ((changedProvider && isLocationMap) || !companyThemeOptions) yield call(selectDefaultTheme);
  else yield call(useCompanyThemeOptions);

  const { themeOptions: newThemeOptions } = yield select(getProjectConfig);
  return cloneDeep(newThemeOptions);
}

export function* setTemplate(params) {
  try {
    let { template: t, useUserData } = params.data;
    if (!t) return;

    const template = cloneDeep(t);

    const config = yield select(getProjectConfig);
    const chartConfig = yield select(getChartConfig);
    const previousProvider = config.provider;
    const changedProvider = template.provider !== previousProvider;

    let chosenWizardTemplate = false;
    if (template.sampleSet) chosenWizardTemplate = template;

    const provider = chosenWizardTemplate?.provider ?? 'highcharts';
    const isLocationMap = provider === 'locationMap';

    yield put(
      setProjectConfigAction({
        provider,
        loadedTemplate: false,
        inPackagesMode: false
      })
    );

    let customizedOptions = getDefaultOptions(template.provider, template.isMap);

    const isNowTilemap = isTilemap(chosenWizardTemplate);
    let dropdownValues = createDropdownValues(chartConfig, isNowTilemap, customizedOptions);

    ['yAxis', 'colorAxis'].forEach((key) => {
      if (customizedOptions[key]) delete customizedOptions[key];
    });

    const templateMeta = [
      {
        templateTitle: template.title,
        templateHeader: template.advancedParent,
        templateParseData: template.parseData,
        showOnlyOneSeriesInData: template.showOnlyOneSeriesInData
      }
    ];

    const projectConfigProps = {};
    const themeOptions = yield call(setDefaultThemeFromTemplate, changedProvider, isLocationMap);

    if (useUserData) {
      (customizedOptions.series ?? []).forEach((series) => (series.type = template?.config?.chart?.type ?? 'line'));
    } else {
      let dataOptions = yield call(setTemplateData, customizedOptions, chosenWizardTemplate, isNowTilemap);
      let { defaultOptions } = dataOptions;
      customizedOptions = dataOptions.customizedOptions;

      if (defaultOptions?.customizedOptions?.colorAxis?.[0]) {
        customizedOptions.colorAxis = defaultOptions.customizedOptions.colorAxis[0];
      }

      if (defaultOptions.customizedOptions) {
        merge(template.config, merge({}, defaultOptions.customizedOptions));
      }

      if (isLocationMap) {
        projectConfigProps.dataOptions = [];
      } else {
        projectConfigProps.dataOptions = parseCSV(defaultOptions.data);
      }
    }

    const isEvervizTheme = config.themeMeta.isevervizTheme === true || config.themeMeta.name === 'everviz';

    if (!isEvervizTheme) {
      let mapColorAxis;
      if (template.config?.colorAxis) {
        mapColorAxis = cloneDeep(
          Array.isArray(template.config.colorAxis) ? template.config.colorAxis[0] : template.config.colorAxis
        );
      } else {
        mapColorAxis = {};
      }
      updateColorAxis(mapColorAxis, { ...projectConfigProps, seriesAssigns: config.seriesAssigns }, themeOptions);
    }
    let themeMeta = merge({}, config.themeMeta);

    let plugins = {};
    let pluginConfig = {};

    if (chosenWizardTemplate.plugins) {
      chosenWizardTemplate.plugins.forEach((p) => {
        plugins[p] = true;
        if (templates[p]?.config) {
          pluginConfig = templates[p].config;
        }
      });
    }

    if (chosenWizardTemplate.isMap) plugins.map = 1;

    if (template.config?.chart?.type) {
      if (!customizedOptions.chart) customizedOptions.chart = {};
      customizedOptions.chart.type = template.config.chart.type;
    } else if (customizedOptions.chart) delete customizedOptions.chart.type;

    if (provider === 'locationMap') {
      projectConfigProps.locationMapOptions = {
        markerPresets: getMarkerPresets(),
        primaryMapRef: null,
        secondaryMapRef: null,
        markerMetadata: {},
        layerOptions: {}
      };
    }

    yield put(
      setChartAction({
        chosenWizardTemplate,
        constr: chosenWizardTemplate.constructor || 'Chart',
        ...dropdownValues
      })
    );

    yield put(
      setProjectConfigAction({
        customizedOptions,
        templateOptions: [merge({}, template.config || {})],
        themeOptions,
        themeMeta,
        plugins,
        templateMeta,
        changeMade: true,
        pluginConfig,
        useDefaultData: !useUserData,
        ...projectConfigProps
      })
    );

    yield call(updateAggregated, true);
    yield call(forceFontResize);

    if (useUserData) {
      const { dataType, dataOptions } = getDataProperties(config.aggregatedOptions, config.dataOptions);
      yield call(setData, {
        data: {
          options: dataOptions,
          dataType,
          skipNotification: true
        }
      });
    }

    yield put(
      setProjectConfigAction({
        loadedTemplate: true
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function getTemplateData(params) {
  const { template } = params.data;

  if (template?.[0]?.templateHeader && template[0].templateTitle) {
    const header = template[0].templateHeader;
    const title = template[0].templateTitle;
    return allTemplates[header]?.templates?.[title] ?? false;
  }

  return false;
}

/** Watch functions */
export function* watchSetTemplate() {
  yield takeEvery(actionTypes.chartEditor.setTemplate, setTemplate);
}

export default function* rootSaga() {
  yield all([watchSetTemplate()]);
}
