import Tippy from '@tippyjs/react';
import { animate } from 'framer-motion';
import { debounce, throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'tippy.js/animations/shift-away.css';
import { selectLocationMap } from '../../../redux/reducers/locationMap/instanceReducer';
import {
  fitMapToFeatures,
  selectNavigationLockError,
  selectViewIsLocked,
  setMapViewError,
  setViewIsLocked
} from '../../../redux/reducers/locationMap/viewStateReducer';
import { TooltipPlacement } from '../../buttons/ButtonContainer';
import IconButton from '../../buttons/IconButton';

const wiggleAnimation = {
  rotate: [0, -10, 10, -8, 8, -5, 5, 0],
  scale: [1, 1.3, 1],
  duration: 0.75
};
const MoveAroundErrorContent = (
  <>
    <i className={'iconLeft fa fa-solid fa-lock text-ev-navy-blue-2 text-lg pr-2'} />
    <span className="font-bold">Unlock the map to move around</span>
  </>
);
const MarkerOutsideViewContent = (
  <>
    <i className={'iconLeft fa fa-solid fa-location-dot text-ev-navy-blue-2 text-lg pr-2'} />{' '}
    <span className="font-bold">Marker placed outside view</span>
  </>
);

const throttledFunc = throttle(
  (cb: () => void) => {
    cb();
  },
  2000,
  { leading: true, trailing: false }
);
const debounceTimeout = debounce(
  (cb: () => void) => {
    setTimeout(() => {
      cb();
    }, 3000);
  },
  3000,
  { leading: true, trailing: false }
);

export type LocationMapNavigationControlsProps = {
  tooltipPlacement: TooltipPlacement;
  showDivider: boolean;
};
export const LocationMapNavigationControls = (props: LocationMapNavigationControlsProps) => {
  const dispatch = useDispatch();
  const locationMap = useSelector(selectLocationMap);

  const navigationLockError = useSelector(selectNavigationLockError);

  const viewIsLocked = useSelector(selectViewIsLocked);

  const lockRef = useRef(null);
  const [showNavigationError, setShowNavigationError] = useState(false);
  const [showOutsideViewError, setShowOutsideViewError] = useState(false);
  const map = locationMap && locationMap.getMapEngine();

  useEffect(() => {
    if (navigationLockError === 'NONE') {
      setShowNavigationError(false);
      setShowOutsideViewError(false);
    } else {
      debounceTimeout.cancel();
      if (navigationLockError === 'MOVE') {
        setShowNavigationError(true);
        setShowOutsideViewError(false);
      } else {
        setShowNavigationError(false);
        setShowOutsideViewError(true);
      }
      debounceTimeout(() => dispatch(setMapViewError('NONE')));

      if (lockRef.current) {
        animate(lockRef.current, wiggleAnimation);
      }
    }
  }, [navigationLockError]);

  const onMouseEnter = useCallback(() => {
    if (viewIsLocked) throttledFunc(() => animate(lockRef.current, wiggleAnimation));
  }, [viewIsLocked, wiggleAnimation]);

  // TODO Error shows up after move warning
  return (
    <>
      <IconButton
        icon={'fa fa-solid fa-plus text-lg'}
        tooltipText={viewIsLocked ? 'Unlock map to zoom in' : 'Zoom in'}
        tooltipPlacement={props.tooltipPlacement}
        disabled={viewIsLocked}
        onMouseEnter={onMouseEnter}
        onClick={() => map?.zoomIn()}
      />
      <IconButton
        icon={'fa fa-solid fa-minus text-lg'}
        tooltipText={viewIsLocked ? 'Unlock map to zoom out' : 'Zoom out'}
        tooltipPlacement={props.tooltipPlacement}
        disabled={viewIsLocked}
        onMouseEnter={onMouseEnter}
        onClick={() => map?.zoomOut()}
      />
      <IconButton
        icon={'fa fa-solid fa-compass text-lg'}
        tooltipText={viewIsLocked ? 'Unlock map to reset orientation to north' : 'Reset orientation to north'}
        tooltipPlacement={props.tooltipPlacement}
        disabled={viewIsLocked}
        onMouseEnter={onMouseEnter}
        onClick={() => map?.easeTo({ bearing: 0 })}
      />
      <IconButton
        icon={'fa fa-solid fa-angle-90 text-lg'}
        tooltipText={viewIsLocked ? 'Unlock map to reset map angle' : 'Reset map angle'}
        tooltipPlacement={props.tooltipPlacement}
        disabled={viewIsLocked}
        onMouseEnter={onMouseEnter}
        onClick={() => map?.easeTo({ pitch: 0 })}
      />
      <IconButton
        icon={'fa fa-solid fa-square-dashed text-xl'}
        tooltipText={viewIsLocked ? 'Unlock map to fit map view to marker' : 'Fit map view to marker'}
        tooltipPlacement={props.tooltipPlacement}
        disabled={viewIsLocked}
        onMouseEnter={onMouseEnter}
        onClick={() => dispatch(fitMapToFeatures())}
      />
      {props.showDivider && <div className="border w[1px] h-2/3" />}

      <Tippy
        animation="shift-away"
        placement={props.tooltipPlacement}
        visible={showOutsideViewError}
        allowHTML={true}
        content={MarkerOutsideViewContent}
        theme={'warning'}
      >
        <Tippy
          animation="shift-away"
          placement={props.tooltipPlacement}
          visible={showNavigationError}
          content={MoveAroundErrorContent}
          theme={'error'}
        >
          <div ref={lockRef} className="h-6 w-6 flex items-center justify-center">
            {viewIsLocked ? (
              <IconButton
                icon={'fa fa-solid fa-lock text-ev-error text-lg'}
                tooltipText="Unlock current view"
                tooltipPlacement={props.tooltipPlacement}
                onClick={() => dispatch(setViewIsLocked(false))}
              />
            ) : (
              <IconButton
                icon={'fa fa-solid fa-lock-open text-lg'}
                tooltipText="Lock current view"
                tooltipPlacement={props.tooltipPlacement}
                onClick={() => dispatch(setViewIsLocked(true))}
              />
            )}
          </div>
        </Tippy>
      </Tippy>
    </>
  );
};
