import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationMapContainerRef } from '@visual-elements/location-map';
import { RootState } from '../../store';
import { revertLocationMapState } from '../../actions/locationMap';
import { startAppListening } from 'redux/listenerMiddleware';

type DefinedLocationMap = Extract<LocationMapContainerRef, { mapDefined: true }>;

export type LocationMapInstanceState =
  | { loadingState: 'none'; locationMap: null; previewMap: null; mapDefined: false }
  | {
      locationMap: LocationMapContainerRef;
      previewMap: LocationMapContainerRef;
      mapDefined: false;
      loadingState: 'initial';
    }
  | {
      loadingState: 'initializing';
      mapDefined: true;
      locationMap: DefinedLocationMap;
      previewMap: DefinedLocationMap;
    }
  | {
      loadingState: 'initialized';
      mapDefined: true;
      locationMap: DefinedLocationMap;
      previewMap: DefinedLocationMap;
    };

const initialState: LocationMapInstanceState = {
  locationMap: null,
  previewMap: null,
  mapDefined: false,
  loadingState: 'none'
};

const locationMapInstanceSlice = createSlice({
  name: 'locationMapInstance',
  initialState: initialState as LocationMapInstanceState,
  extraReducers: (builder) => builder.addCase(revertLocationMapState, () => initialState),
  reducers: {
    storeLocationMapContainerRef(
      state,
      action: PayloadAction<{
        editorMapRef: LocationMapContainerRef;
        previewMapRef: LocationMapContainerRef;
      }>
    ) {
      if (action.payload.editorMapRef.mapDefined && action.payload.previewMapRef.mapDefined) {
        return {
          loadingState: 'initializing',
          locationMap: action.payload.editorMapRef,
          previewMap: action.payload.previewMapRef,
          mapDefined: true
        };
      }

      return {
        loadingState: 'initial',
        locationMap: action.payload.editorMapRef,
        previewMap: action.payload.previewMapRef,
        mapDefined: false
      };
    },
    setLocationMapInitialized(state) {
      state.loadingState = 'initialized';
    }
  }
});

export const selectLocationMap = (state: RootState) => {
  if (
    state.locationMapInstance.loadingState === 'initializing' ||
    state.locationMapInstance.loadingState === 'initialized'
  ) {
    return state.locationMapInstance.locationMap;
  } else return null;
};

// Get the location map instance if finished loading
export const selectInitializedLocationMap = (state: RootState) => {
  if (state.locationMapInstance.loadingState === 'initialized') {
    return state.locationMapInstance.locationMap;
  } else return null;
};

export const { storeLocationMapContainerRef } = locationMapInstanceSlice.actions;
const { setLocationMapInitialized } = locationMapInstanceSlice.actions;

export default locationMapInstanceSlice.reducer;

startAppListening({
  actionCreator: storeLocationMapContainerRef,
  effect: (action, listenerApi) => {
    const editorMapRef = action.payload.editorMapRef;

    if (editorMapRef.mapDefined) {
      editorMapRef.on('initialized', () => {
        listenerApi.dispatch(setLocationMapInitialized());
      });
    }
  }
});
