import paths from 'paths';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import Modal from 'shared/modal/Modal';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import ContactUsModal from 'pages/SubscriptionPage/components/ContactUsModal';

type RelevantPlans = SubscriptionPlans.Free | SubscriptionPlans.Churned;

const text: Record<
  RelevantPlans,
  {
    header: string;
    text: ReactNode;
    secondaryText: ReactNode;
  }
> = {
  [SubscriptionPlans.Churned]: {
    header: 'We miss you!',
    text: (
      <p>
        We noticed you havent been with us for a while, and we&apos;d love to have you back! Reactivating your access is
        quick and easy.
      </p>
    ),
    secondaryText: <p>Simply tap the button below to reactivate your access and get started again:</p>
  },
  [SubscriptionPlans.Free]: {
    header: 'Thank you for trying everviz!',
    text: (
      <p>
        Recently, we changed our licensing model on the plan you are on from a free to 14-day trial. Unfortunately, this
        means your trial and free use of everviz has expired. Although you no longer have access to the everviz
        dashboard, rest assured that all your projects are safe.
      </p>
    ),
    secondaryText: <></>
  }
};

export default function TrialExpiredModal() {
  const { subscription } = useSelector((state: RootState) => state.profile);
  const [isOpen, setIsOpen] = useState(true);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const releaseDate = new Date('2024-01-09').toISOString(); // 14-day Pro trial feature release date
  const isPreviousUser = subscription?.created_time < releaseDate || subscription?.created_time === null;
  const planTextType = (subscription && getPlanById(subscription?.id)) as RelevantPlans;
  const planText = text[planTextType ?? SubscriptionPlans.Free];

  const handleClose = () => {
    if (location.pathname.includes(paths.profileSubscription)) setIsOpen(false);
  };

  const handleOnClick = () => {
    history.push(paths.profileSubscription);
    handleClose();
  };

  useEffect(() => {
    setIsOpen(
      subscription &&
        (getPlanById(subscription?.id) === SubscriptionPlans.Free ||
          getPlanById(subscription?.id) === SubscriptionPlans.Churned) &&
        !location.pathname.includes(paths.profileSubscription)
    );
  }, [subscription, location.pathname]);

  const toggleContactUsModal = () => {
    setIsOpen(!isOpen);
    setShowContactUsModal(!showContactUsModal);
  };

  return (
    <div>
      <ContactUsModal isOpen={showContactUsModal} close={toggleContactUsModal} />

      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        title={<div className="flex gap-2 items-center">{planText?.header ?? ''}</div>}
        width="w-5/12 max-w-xl"
        bg="bg-ev-yellow-1"
        showCloseButton={false}
      >
        <div className="flex items-center justify-center ev-sm:px-12 pb-5">
          <img alt="trial image" src="https://app.everviz.com/static/images/trial.svg" />
        </div>

        <div className="hc-premium-text text-center">
          {isPreviousUser ? (
            planText?.text ?? <></>
          ) : (
            <p>
              Your 14-day free trial has expired. Although you no longer have access to the everviz dashboard, rest
              assured that all your projects are safe.
            </p>
          )}

          {planTextType === SubscriptionPlans.Free && (
            <p>
              Let&rsquo;s keep your creativity alive! To continue using everviz, upgrade to our Pro plan or&nbsp;
              <a className="cursor-pointer" onClick={toggleContactUsModal}>
                contact us for assistance.
              </a>
            </p>
          )}
        </div>

        <div className="flex justify-center pt-6 -mb-6">
          <PrimaryButton onClick={handleOnClick} buttonColor={ButtonColor.Red} text="Upgrade now" />
        </div>
      </Modal>
    </div>
  );
}
