import React, { useState } from 'react';
import Accordion from 'shared/Accordion';
import LiveSourceSection from './LiveSourceSection';
import SeparatorTab from './SeparatorTab';
import DateFormatTab from './DateFormatTab';
import MatchingKeyTab from './MatchingKeyTab';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import ClipboardDark from 'static/icons/clipboard_dark.svg';
import ClipboardLight from 'static/icons/clipboard_light.svg';
import ArrowUpTrayLight from 'static/icons/arrow_up_tray_light.svg';
import ArrowUpTrayDark from 'static/icons/arrow_up_tray_dark.svg';
import LinkDark from 'static/icons/link_dark.svg';
import LinkLight from 'static/icons/link_light.svg';

type ImportOptionsProps = {
  togglePasting: () => void;
  setShowLinkDataModal: React.Dispatch<React.SetStateAction<boolean>>;
  isChart: boolean;
  isMap: boolean;
  isPackedBubble: boolean;
  isTileMap: boolean;
  isPointMap: boolean;
  onClickUpload: () => void;
  aggregatedOptions: any;
  isLiveDataSource: boolean;
  displaySeparatorTab: boolean;
};

const linkButtonClassName = 'font-medium underline py-4 text-center cursor-pointer hover:text-ev-baby-blue-2';
const iconButtonBaseClasses = 'w-[70px] h-[70px] rounded-full cursor-pointer content-centered';

const ImportOptions = ({
  isPackedBubble,
  togglePasting,
  isChart,
  isMap,
  setShowLinkDataModal,
  onClickUpload,
  aggregatedOptions,
  isLiveDataSource,
  displaySeparatorTab,
  isPointMap,
  isTileMap
}: ImportOptionsProps) => {
  const isUsingRefinitivData = aggregatedOptions?.everviz?.data?.symbols;
  const useHover = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return { isHovered, handleMouseEnter, handleMouseLeave };
  };

  const {
    isHovered: isClipboardHovered,
    handleMouseEnter: handleDivMouseEnter,
    handleMouseLeave: handleDivMouseLeave
  } = useHover();
  const {
    isHovered: isUploadHovered,
    handleMouseEnter: handleUploadMouseEnter,
    handleMouseLeave: handleUploadMouseLeave
  } = useHover();
  const {
    isHovered: isLinkHovered,
    handleMouseEnter: handleLinkMouseEnter,
    handleMouseLeave: handleLinkMouseLeave
  } = useHover();

  return (
    <Accordion headerText="Add your own data" useInternalSelect={true}>
      <div>Use the options below if you want to import your own data</div>
      <div className="grid gap-4 pt-4 grid-cols-3">
        <div className="flex flex-col items-center">
          <SvgIconButton
            Icon={isClipboardHovered ? ClipboardLight : ClipboardDark}
            onClick={() => togglePasting()}
            buttonClasses={iconButtonBaseClasses}
            buttonColor={ButtonColor.White}
            width={24}
            height={36}
            onMouseEnter={handleDivMouseEnter}
            onMouseLeave={handleDivMouseLeave}
          />
          <div className={`${linkButtonClassName} ${isClipboardHovered && 'text-ev-baby-blue-2'}`}>Paste</div>
        </div>

        <div className="flex flex-col items-center">
          <SvgIconButton
            Icon={isUploadHovered ? ArrowUpTrayLight : ArrowUpTrayDark}
            onClick={onClickUpload}
            buttonClasses={iconButtonBaseClasses}
            buttonColor={ButtonColor.White}
            width={32}
            height={36}
            onMouseEnter={handleUploadMouseEnter}
            onMouseLeave={handleUploadMouseLeave}
            id="ev-upload-data-icon"
          />
          <div className={`${linkButtonClassName} ${isUploadHovered && 'text-ev-baby-blue-2'}`}>Upload</div>
        </div>

        {((isChart && !isPointMap && !isTileMap && !isPackedBubble) || !isChart) && (
          <div className="flex flex-col items-center">
            <SvgIconButton
              Icon={isLinkHovered ? LinkLight : LinkDark}
              onClick={() => setShowLinkDataModal(true)}
              buttonClasses={iconButtonBaseClasses}
              buttonColor={ButtonColor.White}
              width={40}
              height={36}
              onMouseEnter={handleLinkMouseEnter}
              onMouseLeave={handleLinkMouseLeave}
              id="ev-link-data-icon"
            />
            <div className={`${linkButtonClassName} ${isLinkHovered && 'text-ev-baby-blue-2'}`}>Link data</div>
          </div>
        )}
      </div>

      <hr className="border-t border-t-ev-grey-3 my-4" />
      {isLiveDataSource && !isUsingRefinitivData && (
        <LiveSourceSection aggregatedOptions={aggregatedOptions} setShowLinkDataModal={setShowLinkDataModal} />
      )}

      <div className="mb-2">
        For help adding data, check out our help articles under{' '}
        <a
          className="font-bold"
          href="https://help.everviz.com/category/318-working-with-data"
          target={'_blank'}
          rel="noreferrer"
        >
          Working with data
        </a>
      </div>

      <hr className="border-t border-t-ev-grey-3 my-4" />
      {!isMap && <DateFormatTab />}
      {isMap && <MatchingKeyTab />}
      {!isLiveDataSource && displaySeparatorTab && !isMap && (
        <>
          <SeparatorTab />
        </>
      )}
    </Accordion>
  );
};

export default ImportOptions;
