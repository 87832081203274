import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInitializedLocationMap } from 'redux/reducers/locationMap/instanceReducer';
import UndoIcon from 'static/icons/undo.svg';
import {
  resetStyledMapLayersAction,
  updateStyledMapLayersAction
} from '../../../../pages/ChartEditorPage/actions/locationMap';
import {
  CustomEvervizStyleSpecification,
  LayerGroupMetadata
} from '../../../../pages/ChartEditorPage/middleware/LocationMap/LocationMapCustomize';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import { ButtonColor } from '../../../buttons/types/ButtonModels';
import AccordionButton from '../../../widgets/AccordionButton';
import CheckboxWidget from '../../../widgets/CheckboxWidget';
import { SectionNode } from '../SectionNode';

export type LayersSectionProps = {
  isCompact?: boolean;
  showContainer?: boolean;
  openedByDefault?: boolean;
};

export const LayersSection = ({ isCompact, showContainer, openedByDefault }: LayersSectionProps) => {
  const { locationMapOptions, themeOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const locationMap = useSelector(selectInitializedLocationMap);

  const dispatch = useDispatch();

  const onClickLayer = (name: string, value: boolean) => {
    dispatch(
      updateStyledMapLayersAction({
        id: name,
        visible: value,
        group: true
      })
    );
  };

  const resetLayers = () => {
    dispatch(resetStyledMapLayersAction());
  };

  const currentTheme = useRef<undefined | string | object>();
  const [layers, setLayers] = useState<LayerGroupMetadata[]>([]);

  useEffect(() => {
    const setSortedLayers = (style: CustomEvervizStyleSpecification) => {
      const layerGroups = style.metadata['everviz:groups']?.filter((x) => (isCompact ? !x.advanced : true));
      if (!layerGroups) return;

      const sortedLayers = layerGroups.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
      setLayers(sortedLayers);
    };
    const setMapLayers = () => {
      if (!locationMap) return;
      const newStyle = locationMap.getMapEngine().getStyle() as CustomEvervizStyleSpecification | undefined;
      if (!newStyle || !themeOptions.theme) return;
      if (themeOptions.theme.type === 'dynamic') {
        if (currentTheme.current != themeOptions.theme.url) {
          currentTheme.current = themeOptions.theme.url;
          setSortedLayers(newStyle);
        }
      } else if (themeOptions.theme.type === 'static') {
        if (currentTheme.current != themeOptions.theme.data) {
          currentTheme.current = themeOptions.theme.data;
          setSortedLayers(newStyle);
        }
      }
    };
    setMapLayers();
  }, [themeOptions.theme, locationMap]);

  return (
    <SectionNode
      showContainer={showContainer}
      sectionKey="map-layers"
      headerText="Map layers"
      openedByDefault={openedByDefault}
    >
      {layers.map((layerGroup, index) => (
        <CheckboxWidget
          key={index}
          value={
            locationMapOptions.layerOptions[layerGroup.id]
              ? locationMapOptions.layerOptions[layerGroup.id].visible
              : layerGroup.defaultVisibility === 'visible'
          }
          onChange={(e) => onClickLayer(layerGroup.id, e.val as boolean)}
          label={layerGroup.id}
        />
      ))}

      <AccordionButton
        handleClick={resetLayers}
        label="Reset layers"
        size={16}
        buttonIcon={UndoIcon}
        buttonColor={ButtonColor.White}
        iconClasses="p-2 rounded-full mx-2"
      />
    </SectionNode>
  );
};
