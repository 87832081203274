import { getSeriesType } from 'shared/wizard/utils/seriesHelper';
import { isGeoJsonMap } from '../utils/geoJsonHelper';
import { cloneDeep, isArray, isNull, isString, uniqBy } from 'lodash';
import { geoJsonMapId } from 'pages/ChartEditorPage/utils/geoJsonHelper';
import snackBar from 'editor/core-ui/highed.snackbar';

export function getRandomMapData(sections, code) {
  return (
    'code;value\n' +
    sections
      .map((section) => {
        return [section.properties[code ?? 'hc-key'], Math.floor(Math.random() * 100) + 1].join(';');
      })
      .join('\n')
  );
}

export function isMapKeyIncompatible(mapData) {
  return mapData.some(function (d) {
    let prop = d.properties && d.properties['hc-key'];
    if (prop) {
      prop = prop.split('-');
      return prop.length - 1 >= 2 && prop[prop.length - 1].length <= 4;
    }
    return false;
  });
}

export function getCompatibleMapKey(mapData) {
  let foundCode;
  // eslint-disable-next-line array-callback-return
  mapData.some(function (d) {
    /*
     
    //Uncomment this when bug in highmaps fixed:
    https://github.com/highcharts/highcharts/issues/16920

    if (d.id && d.properties && d.properties['fips']) {
      foundCode = 'fips';
      return true;
    }*/
    if (d.id && d.properties && d.properties.name) {
      foundCode = 'name';
      return true;
    }
    if (d.id && d.properties && d.properties['hc-a2']) {
      foundCode = 'hc-a2';
      return true;
    }
  });
  return foundCode;
}

export function getMapKey(aggregatedOptions) {
  const seriesType = getSeriesType(aggregatedOptions);
  const isMapBubble = seriesType.includes('mapbubble');
  const seriesIndex = isMapBubble ? 1 : 0;
  let series = aggregatedOptions?.series?.[seriesIndex]?.joinBy?.[0];
  if (isMapBubble) {
    const seriesJoinBy = aggregatedOptions?.series?.[seriesIndex]?.joinBy;
    series = isArray(seriesJoinBy) ? series : aggregatedOptions?.series?.[seriesIndex]?.joinBy;
  }
  return series ?? 'hc-key';
}

export const getAcceptedValues = (mapData, mapKey) => {
  // Show all the values from the geojson file the user uploaded.
  if (mapKey === geoJsonMapId) {
    return mapData?.[0]?.properties;
  }

  const acceptedValues = {
    'hc-key': 1,
    'postal-code': 1,
    hasc: 1,
    'state-fips': 1,
    fips: 1,
    name: 1,
    'iso-a2': 1,
    'iso-a3': 1,
    bundesland: 1,
    'hc-a2': 1,
    kommune: 1
  };

  const firstSectionProps = mapData?.[0]?.properties ?? {};

  // These seem to be the same, remove hc-a2 if postal code is also in the properties
  if (firstSectionProps['hc-a2'] && firstSectionProps['postal-code']) {
    delete acceptedValues['hc-a2'];
  }

  const countryProperties = (mapData ?? []).map((prop) => prop.properties.country);
  const uniqueCountries = uniqBy(countryProperties);
  const areCountriesUnique = uniqueCountries.length === countryProperties.length;

  // Only add the countries if they're unique
  // Tbh I dont even know if we would get into this state but I guess it kinda seems logical
  // that there might be cases where you would want to map the country. Hard to say without checking
  // every single map though.
  if (areCountriesUnique) acceptedValues.country = 1;

  return acceptedValues;
};

export function getMatchingKey(customizedOptions, options, mapData, acceptedValues) {
  const keys = mapData ? Object.keys(mapData[0].properties) : {};
  const firstColValues = options.slice(1).map((item) => item[0]);
  let propertyArrays = mapData
    ? keys.map((key) => {
        return mapData
          .filter(
            (item) =>
              item.properties &&
              key in item.properties &&
              item.properties[key] !== '__separator_lines__' &&
              key in acceptedValues
          )
          .map((item) => item.properties[key]);
      })
    : [];

  const calculateLikelihood = (firstArray, comparableArray) => {
    const totalElements = firstArray.length;
    if (totalElements === 0) return 0;
    let matchingCount = 0;

    const firstSet = new Set(firstArray.map((item) => (item ?? '').toString().toLowerCase()));
    const comparableSet = new Set(comparableArray.map((item) => (item ?? '').toString().toLowerCase()));

    firstSet.forEach((item) => {
      if (comparableSet.has(item)) {
        matchingCount++;
      }
    });

    return matchingCount / totalElements;
  };

  let maxLikelihood = 0,
    matchingKey;
  for (let i = 0; i < propertyArrays.length; i++) {
    const propertyValues = propertyArrays[i];
    const likelihood = calculateLikelihood(propertyValues, firstColValues);

    if (likelihood > maxLikelihood) {
      maxLikelihood = likelihood;
      matchingKey = keys[i];
    }
  }

  return matchingKey;
}

function getNonMatchingCodes(array1, array2, useIndex) {
  const array2Set = new Set(array2.map((item) => item?.toString()));

  return array1
    .map((element, index) => {
      const comparisonElement = useIndex ? index : element?.toString();
      return array2Set.has(element?.toString()) ? -1 : comparisonElement;
    })
    .filter((e) => e !== undefined && e !== -1 && !isNull(e));
}

export function getMapCodeErrorFields(customizedOptions, options, mapCodeIndex, matchingKey, mapData) {
  const isTilemap = customizedOptions?.chart?.type === 'tilemap';
  if (isTilemap) return [];

  const mapCodeValues = mapData?.map((data) => data.properties[matchingKey]);

  const userCodeValues = options.map((data) => data[mapCodeIndex]);
  userCodeValues.shift();

  const mapExtraColumns = getNonMatchingCodes(mapCodeValues, userCodeValues);
  const mapCodeErrors = getNonMatchingCodes(userCodeValues, mapCodeValues, true);

  return {
    mapCodeErrors,
    mapExtraColumns
  };
}

export function parseCodesForCaseSensitivity(customizedOptions, dataOptions, mapCodeIndex, matchingKey, mapData) {
  const isTilemap = customizedOptions?.chart?.type === 'tilemap';
  if (isTilemap) return dataOptions;

  const options = cloneDeep(dataOptions);
  const mapCodeValues = mapData?.map((data) => data.properties[matchingKey]);
  const lowerCasedMapCodeValues = mapCodeValues?.map((data) => (data ?? '').toString().toLowerCase());

  const userCodeValues = options.map((data) => data[mapCodeIndex]);
  userCodeValues.shift();

  userCodeValues.forEach((userCode, index) => {
    if (isString(userCode)) {
      const mapIndex = lowerCasedMapCodeValues.indexOf((userCode ?? '').toLowerCase());
      if (mapIndex > -1) options[index + 1][mapCodeIndex] = mapCodeValues[mapIndex];
    }
  });

  return options;
}

export function isShortCode(mData, code) {
  return (
    (mData.properties['iso-a2'] && mData.properties['iso-a2'] === code) ||
    (mData.properties['hc-a2'] && mData.properties['hc-a2'] === code)
  );
}

export function isLongCode(mData, code) {
  return mData.properties['iso-a3'] && mData.properties['iso-a3'] === code;
}

export const isTilemap = (chosenWizardTemplate) =>
  chosenWizardTemplate?.title && ['Tilemap circle', 'Honeycomb'].indexOf(chosenWizardTemplate.title) > -1;
export const isPointMap = (chosenWizardTemplate) =>
  chosenWizardTemplate?.title && ['Point map'].indexOf(chosenWizardTemplate.title) > -1;
export const isPatternFill = (chosenWizardTemplate) =>
  chosenWizardTemplate?.title && ['Pattern fill'].indexOf(chosenWizardTemplate.title) > -1;
export const isPointMapFromConfig = (options) =>
  options?.chart?.type === 'pointmap' || options?.series?.some((serie) => serie.type === 'mappoint');

export function getMapJoinByKeyValue(config, customizedOptions, matchingKey) {
  const mapType = config?.templateMeta?.[0].templateHeader;
  const seriesIndex = mapType === 'Bubble' ? 1 : 0;
  const customGeoJson = customizedOptions?.chart?.map;
  const isCustomGeoJson = customGeoJson && isString(customGeoJson) && isGeoJsonMap(customGeoJson);
  const updatableValue = mapType === 'Bubble' && !isCustomGeoJson ? matchingKey : [matchingKey, matchingKey];

  return [`series[${seriesIndex}].joinBy`, updatableValue];
}

export async function fetchCsv(csvURL) {
  try {
    const response = await fetch(csvURL);
    if (response.ok) {
      const csvData = await response.text();
      return csvData;
    } else {
      console.error('Failed to fetch CSV:', response.statusText);
      snackBar(`Failed to fetch CSV: ${response.statusText}`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching CSV:', error);
    snackBar('Error fetching CSV');
    return null;
  }
}

export async function fetchGoogleSheetData(sheetKey, range) {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetKey}/values/${range}?key=AIzaSyCchblEzIdk4rPKD6sbi72c4OseEdqmyPQ`;
  const response = await fetch(url);
  if (!response.ok) {
    snackBar('Failed to fetch Google Sheet data');
    return null;
  }
  return await response.json();
}
