import { nanoid } from '@reduxjs/toolkit';
import Tippy from '@tippyjs/react';
import { MapMouseEvent } from '@visual-elements/maplibre-gl';
import classNames from 'classnames';
import { ProjectConfigLocationMapProps } from 'pages/Editor/reducers/locationMapConfigTypes';
import React, { forwardRef, ReactNode, useEffect, useRef, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocationMap } from 'redux/reducers/locationMap/instanceReducer';
import { selectViewIsLocked } from 'redux/reducers/locationMap/viewStateReducer';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { useOnClickOutside } from 'usehooks-ts';
import {
  addLocationMapMarkerAction,
  removeLocationMapMarkerAction
} from '../../../../pages/ChartEditorPage/actions/locationMap';
export const ContextMenu = () => {
  const dispatch = useDispatch();
  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const locationMap = useSelector(selectLocationMap);

  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [, startTransition] = useTransition();
  const [pointResult, setPointResult] = useState<
    { id?: string | number; label?: string; coordinates: [number, number] } | undefined
  >();
  const [selectedFeature, setSelectedFeature] = useState<{ id: string; type: 'marker' | 'area' } | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);

  const viewIsLocked = useSelector(selectViewIsLocked);
  useOnClickOutside(containerRef, () => setShowContextMenu(false));
  useEffect(() => {
    if (!locationMap) return;
    const map = locationMap.getMapEngine();
    const handleOnContextMenu = (e: MapMouseEvent & object) => {
      const selectedFeature = locationMap?.getSelectedFeature();
      setSelectedFeature(selectedFeature);
      setShowContextMenu(true);
      setContextMenuPosition([e.point.x, e.point.y]);
      setPointResult({ coordinates: [e.lngLat.lng, e.lngLat.lat] });
      startTransition(() => {
        const renderedFeatures = e.target.queryRenderedFeatures(e.point, {});
        const pointResult: { id?: string | number; label?: string; coordinates: [number, number] } = {
          coordinates: [e.lngLat.lng, e.lngLat.lat]
        };
        for (const renderedFeature of renderedFeatures) {
          if (renderedFeature.layer.type === 'symbol') {
            const textField = renderedFeature.layer.layout?.['text-field'];
            if (textField) {
              const formatString = textField.toString().replace('{', '').replace('}', '');
              if (renderedFeature.geometry.type === 'Point') {
                pointResult.coordinates = [
                  renderedFeature.geometry.coordinates[0],
                  renderedFeature.geometry.coordinates[1]
                ];
              }
              pointResult.label = renderedFeature.properties[formatString];
              pointResult.id = renderedFeature.id;
              break;
            }
          }
        }
        setPointResult(pointResult);
      });
    };
    map.on('contextmenu', handleOnContextMenu);
    return () => {
      map.off('contextmenu', handleOnContextMenu);
    };
  }, [locationMap, locationMap]);
  if (!showContextMenu) return <></>;

  const marker = selectedFeature ? aggregatedOptions.markers.find((x) => x.id === selectedFeature.id) : undefined;
  return (
    <div
      ref={containerRef}
      className="absolute z-10 shadow-lg rounded flex flex-col border-ev-navy-blue bg-white p-2 gap-1 divide-y divide-ev-grey min-w-[215px]"
      tabIndex={0}
      style={{
        left: contextMenuPosition[0] + 5,
        top: contextMenuPosition[1] + 5
      }}
    >
      {pointResult && !selectedFeature && (
        <ContextMenuItem
          onClick={() => {
            dispatch(
              addLocationMapMarkerAction({
                id: nanoid(8),
                label: pointResult.label,
                location: pointResult.coordinates,
                type: 'PinMarker'
              })
            );
            setShowContextMenu(false);
          }}
        >
          <ContextMenuIcon icon="location-dot" />
          <ContextMenuText>
            {pointResult.label ? `Add "${pointResult.label}" as a marker` : 'Add Marker'}
          </ContextMenuText>
        </ContextMenuItem>
      )}
      {selectedFeature && (
        <>
          <ContextMenuItem
            disabled={viewIsLocked}
            disabledTooltipText="Unlock map view to use this feature"
            onClick={() => {
              if (
                marker?.data.type === 'static' &&
                marker.data.content.type === 'Feature' &&
                marker.data.content.geometry.type === 'Point' &&
                locationMap
              ) {
                const zoom = locationMap.getMapEngine().getZoom();
                locationMap.getMapEngine().flyTo({
                  center: [marker.data.content.geometry.coordinates[0], marker.data.content.geometry.coordinates[1]],
                  zoom: zoom > 14 ? zoom : 14
                });
              }
              setShowContextMenu(false);
            }}
          >
            <ContextMenuIcon icon="magnifying-glass-plus" />
            <ContextMenuText>Zoom to marker</ContextMenuText>
          </ContextMenuItem>
          <ContextMenuItem
            disabled={viewIsLocked}
            disabledTooltipText="Unlock map view to use this feature"
            onClick={() => {
              if (
                marker?.data.type === 'static' &&
                marker.data.content.type === 'Feature' &&
                marker.data.content.geometry.type === 'Point' &&
                locationMap
              ) {
                locationMap.getMapEngine().flyTo({
                  center: [marker.data.content.geometry.coordinates[0], marker.data.content.geometry.coordinates[1]]
                });
              }
              setShowContextMenu(false);
            }}
          >
            <ContextMenuIcon icon="arrow-down-left-and-arrow-up-right-to-center" />
            <ContextMenuText>Center on map</ContextMenuText>
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => {
              dispatch(removeLocationMapMarkerAction({ id: selectedFeature.id }));
              setShowContextMenu(false);
            }}
          >
            <ContextMenuIcon icon="trash-can " />
            <ContextMenuText>Delete marker</ContextMenuText>
          </ContextMenuItem>
        </>
      )}
    </div>
  );
};

export const ContextMenuItem = (props: {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  disabledTooltipText?: string;
}) => {
  if (props.disabledTooltipText && props.disabled) {
    return (
      <Tippy theme="warning" className="arrow-theme" content={props.disabledTooltipText} placement="right">
        <ContextMenuItemChild {...props} />
      </Tippy>
    );
  }
  return <ContextMenuItemChild {...props} />;
};

export const ContextMenuItemChild = forwardRef<
  HTMLDivElement,
  { children: ReactNode; onClick: () => void; disabled?: boolean }
>((props, ref) => {
  const className = classNames('pl-2 pr-2 flex items-center bg-ev-grey h-8', {
    'cursor-not-allowed opacity-50': props.disabled,
    'cursor-pointer hover:bg-ev-baby-blue-2': !props.disabled
  });
  return (
    <div ref={ref} className={className} onClick={props.disabled ? () => null : props.onClick}>
      {props.children}
    </div>
  );
});

export const ContextMenuIcon = (props: { icon: string }) => {
  return <i className={`iconLeft fa fa-solid fa-${props.icon} pr-2`} />;
};
export const ContextMenuText = (props: { children: string }) => {
  return <span className="font-bold">{props.children}</span>;
};
