import { format } from 'date-fns';
import { PlanFeature, PlanPeriod, SubscriptionPlan, SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { plansCardsData } from '../meta/plansCardsData';
import { subscriptionPlans } from '../meta/subscriptionPlans';

export type TrialUIInfo = {
  text: string;
  endText: string;
  totalDays: number;
  remainingDays: number;
  dayPercent: number;
  name: string;
  shortName: string;
  dateExpiredText: string;
  dayExpiredText: string;
  billingPeriodText: string;
};

export const standardFeatures = [
  {
    icon: 'paintbrush-pencil',
    size: 'text-2xl',
    header: 'Fully customizable',
    description: 'Visualize your data with dozens of popular chart, map and table types and publish in minutes.'
  },
  {
    icon: 'eye',
    size: 'text-3xl',
    header: 'Engage',
    description: 'Engage viewers with interactive visualizations and combine them using our powerful layout module.'
  },
  {
    icon: 'share-nodes',
    size: 'text-3xl',
    header: 'Share',
    description: 'Embed your interactive chart on your site, social media or download it as a static PNG, PDF or SVG.'
  },
  {
    icon: 'users', // icon: 'people-group',
    size: 'text-2xl',
    header: 'Collaborate',
    description: 'Team accounts provide groups with customized workflows, shared workspaces and galleries.'
  },
  {
    icon: 'arrow-up-right-and-arrow-down-left-from-center',
    size: 'text-3xl',
    header: 'Responsive',
    description: 'Every visualization is intelligently re-configured to each visitors desktop or mobile screen size.'
  },
  {
    icon: 'universal-access',
    size: 'text-3xl',
    header: 'Accessibility',
    description: 'All our graphics have built-in support for accessibility helping you reach a broader audience.'
  },
  {
    icon: 'refresh',
    size: 'text-3xl',
    header: 'Live data import',
    description: 'Keep your data fresh by pulling data directly from a Google Sheet or other external sources.'
  },
  {
    icon: 'clouds',
    size: 'text-2xl',
    header: 'Cloud-a-licious',
    description: 'High-performance online storage, no need to upgrade your site to handle additional traffic'
  },
  {
    icon: 'code',
    size: 'text-2xl',
    header: 'API access & Webhooks',
    description: 'Utilize the power of our API for batch updating and webhooks to trigger external events.'
  },
  {
    icon: 'usd',
    size: 'text-3xl',
    header: 'Scalable',
    description: 'We offer free and inexpensive plans for individuals/businesses.'
  },
  {
    icon: 'columns-3',
    size: 'text-2xl',
    header: 'Powerful storytelling',
    description: 'Combine visualizations into split views, tabs or carousels using our powerful layout module.'
  },
  {
    icon: 'envelope',
    size: 'text-3xl',
    header: 'Support',
    description: 'Compare the different support options available here.'
  }
];

export const getFormattedPlanFeatures = (plan: SubscriptionPlans) => {
  const planFeatures = plansCardsData[plan].features;
  let formattedFeatures: PlanFeature[] = [];

  switch (plan) {
    case SubscriptionPlans.Starter: {
      formattedFeatures = [planFeatures[0], planFeatures[2], planFeatures[1]];
      break;
    }
    case SubscriptionPlans.Business: {
      formattedFeatures = [planFeatures[6], planFeatures[2], planFeatures[1]];
      break;
    }
    case SubscriptionPlans.Pro: {
      formattedFeatures = [planFeatures[0], planFeatures[1], planFeatures[5], planFeatures[6]];
      break;
    }
    case SubscriptionPlans.Enterprise: {
      formattedFeatures = [planFeatures[7], planFeatures[1], planFeatures[0]];
      break;
    }
    default:
      formattedFeatures = [];
  }

  return formattedFeatures;
};

export const getPlanById = (planId: number) => {
  switch (planId) {
    case 1:
      return SubscriptionPlans.Free;
    case 9:
    case 12:
    case 19:
    case 20:
      return SubscriptionPlans.Starter;
    case 10:
    case 13:
    case 16:
    case 18:
      return SubscriptionPlans.Business;
    case 21:
    case 22:
      return SubscriptionPlans.Pro;
    case 23:
      return SubscriptionPlans.ProTrial;
    case 4:
    case 14:
    case 15:
      return SubscriptionPlans.Enterprise;
    case 24:
      return SubscriptionPlans.Churned;
    case 17:
      return SubscriptionPlans.Storage;
    default:
      return SubscriptionPlans.Free;
  }
};

export const getPlanDetailsByNameAndPeriod = (planName: SubscriptionPlans, period: PlanPeriod): SubscriptionPlan => {
  if (period === PlanPeriod.Yearly) {
    switch (planName) {
      case SubscriptionPlans.Starter:
        return subscriptionPlans[12];
      case SubscriptionPlans.Business:
        return subscriptionPlans[13];
      case SubscriptionPlans.Pro:
        return subscriptionPlans[22];
    }
  } else if (period === PlanPeriod.Monthly) {
    switch (planName) {
      case SubscriptionPlans.Starter:
        return subscriptionPlans[9];
      case SubscriptionPlans.Business:
        return subscriptionPlans[10];
      case SubscriptionPlans.Pro:
        return subscriptionPlans[21];
    }
  }
  return subscriptionPlans[13];
};

export const nextPlanMap: Record<number, number> = {
  1: 21,
  9: 13,
  10: 15,
  12: 13,
  13: 15,
  17: 12,
  19: 13,
  20: 13,
  21: 14,
  22: 15,
  23: 21
};

export const yearlyPlanMap: Record<number, number> = {
  9: 12,
  10: 13,
  19: 20,
  21: 22
};

const formatDate = (date: string | undefined) => {
  return date ? format(new Date(date), 'MMMM d, yyyy') : 'N/A';
};

const getDayPercentage = (total: number, remaining: number) => {
  const min = total - remaining;
  if (total === 0) return 100;
  return Math.min((min / total) * 100, 100);
};

export const getTrialPlanUIInfo = (
  plan: SubscriptionPlans,
  remainingDays: number,
  subscription: SubscriptionPlan
): TrialUIInfo | undefined => {
  switch (plan) {
    case SubscriptionPlans.ProTrial: {
      const proTrialEndDate = formatDate(subscription?.period_end_time);
      const totalDays = 14;
      return {
        text: 'Trial ends in',
        endText: 'Trial <span class="font-xs font-bold">expired</span>',
        totalDays: totalDays,
        remainingDays: remainingDays,
        dayPercent: getDayPercentage(totalDays, remainingDays),
        name: 'Free Trial',
        shortName: 'Trial',
        dateExpiredText: `Free Trial will expire on ${proTrialEndDate}`,
        dayExpiredText: `Trial ends in ${remainingDays} ${remainingDays < 2 ? 'day' : 'days'}`,
        billingPeriodText: `Your Pro trial ends on ${proTrialEndDate}. To continue using everviz upgrade to a paid plan now.`
      };
    }
    case SubscriptionPlans.Free:
      return {
        text: 'Trial ended',
        endText: 'Trial <span class="font-xs font-bold">expired</span>',
        totalDays: 0,
        remainingDays: remainingDays,
        dayPercent: getDayPercentage(0, remainingDays),
        name: 'Free Trial',
        shortName: 'Trial',
        dateExpiredText: 'Free Trial has expired',
        dayExpiredText: 'Your trial has expired',
        billingPeriodText: 'Your Pro trial has expired. To continue using everviz upgrade to a paid plan now.'
      };
    default:
      return undefined;
  }
};

export const getRemainingDays = (endTime: string): number => {
  if (!endTime) return 0;

  const currentDate = new Date(new Date().toISOString().substring(0, 10));
  const endDate = new Date(endTime.substring(0, 10));
  const timeDifference = endDate.getTime() - currentDate.getTime();
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
  return dayDifference < 0 ? 0 : dayDifference;
};

export function getLockedForPlans(plan: SubscriptionPlans): SubscriptionPlans[] {
  switch (plan) {
    case SubscriptionPlans.Enterprise:
      return [
        SubscriptionPlans.Business,
        SubscriptionPlans.Pro,
        SubscriptionPlans.ProTrial,
        SubscriptionPlans.Free,
        SubscriptionPlans.Storage,
        SubscriptionPlans.Starter
      ];
    case SubscriptionPlans.Pro:
    case SubscriptionPlans.ProTrial:
      return [SubscriptionPlans.Free, SubscriptionPlans.Storage, SubscriptionPlans.Starter];
    case SubscriptionPlans.Free:
    case SubscriptionPlans.Churned:
      return [SubscriptionPlans.Storage, SubscriptionPlans.Starter];
    case SubscriptionPlans.Storage:
    case SubscriptionPlans.Business:
    case SubscriptionPlans.Starter:
      return [];
  }
}
