import store from '../../../redux/store';

type CustomFont = {
  id: number;
  team_id: number;
  name: string;
  active: number;
  uuid: string;
  font_name: string;
};

export const checkCustomFontsInAggregatedOptions = (options: any) => {
  const customFonts = store.getState().layoutEditorPage.customFonts;

  const findFontFamilyInStyles = (obj: any) => {
    let foundFonts: CustomFont[] = [];

    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        foundFonts = [...foundFonts, ...findFontFamilyInStyles(item)];
      });
    } else if (typeof obj === 'object' && obj !== null) {
      if (
        Object.prototype.hasOwnProperty.call(obj, 'style') &&
        Object.prototype.hasOwnProperty.call(obj.style, 'fontFamily')
      ) {
        const fontName = obj.style.fontFamily;
        const matchingFonts = customFonts.filter(
          (font: CustomFont) => font.font_name.toLowerCase() === fontName.toLowerCase()
        );
        foundFonts = [...foundFonts, ...matchingFonts];
      }

      // Recursively check properties in the object
      Object.keys(obj).forEach((key) => {
        foundFonts = [...foundFonts, ...findFontFamilyInStyles(obj[key])];
      });
    }
    return foundFonts;
  };

  const foundFonts: CustomFont[] = findFontFamilyInStyles(options);

  if (foundFonts.length > 0) {
    const baseUrl = `${window.hcconfig.frontend.hostname}`;
    const links: string[] = foundFonts.map((font: CustomFont) => `${baseUrl}/fonts/${font.uuid}/font.css`);
    return links;
  }

  return [];
};
