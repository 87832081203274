import { loadGoogleSheetAction, loadLiveDataAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor, ButtonType } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import TextButton from 'shared/buttons/TextButton';
import { InputChangeParams } from 'shared/types/commonPropTypes';
import InputWidget from 'shared/widgets/InputWidget';
import { set, cloneDeep } from 'lodash';
import { setDataAction } from 'pages/TableEditorPage/actions/tableEditor';
import { getSheetName, getSheetGid } from '../utils/linkDataHelper';

type LiveSourceSectionProps = {
  aggregatedOptions: any;
  setShowLinkDataModal: (toggle: boolean) => void;
};

const LiveSourceSection = ({ aggregatedOptions, setShowLinkDataModal }: LiveSourceSectionProps) => {
  const dispatch = useDispatch();
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const isTable = type === 'table';
  const googleSpreadsheetRange = aggregatedOptions?.data?.googleSpreadsheetRange;
  const [sheetName, range] = googleSpreadsheetRange ? googleSpreadsheetRange.split('!') : ['', ''];

  const getValue = () => {
    return aggregatedOptions.data.csvURL ?? aggregatedOptions.data.columnsURL ?? aggregatedOptions.data.rowsURL;
  };

  const updateLink = async () => {
    const googleSpreadsheetKey = aggregatedOptions?.data?.googleSpreadsheetKey;

    if (!googleSpreadsheetKey) {
      setLink(getValue());
      return;
    }

    const gid = sheetName ? await getSheetGid(googleSpreadsheetKey, sheetName) : 0;
    const newLink = `https://docs.google.com/spreadsheets/d/${googleSpreadsheetKey}/edit#gid=${gid}&range=${encodeURIComponent(
      range
    )}`;
    setLink(newLink);
  };

  useEffect(() => {
    updateLink();
  }, []);

  useEffect(() => {
    updateLink();
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [aggregatedOptions]);

  const getType = () => {
    const data = aggregatedOptions?.data;
    const keys = ['csvURL', 'columnsURL', 'rowsURL', 'googleSpreadsheetKey'];
    return keys.find((key) => key in data) || null;
  };

  const onChange = (value: InputChangeParams) => {
    setLink(value.val as string);
  };

  const loadModal = () => {
    setShowLinkDataModal(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const sync = async () => {
    setIsLoading(true);
    const type = getType();

    if (isMap && aggregatedOptions?.data?.csvURL) {
      let options = aggregatedOptions?.data || {};
      if (aggregatedOptions?.data?.csvURL !== link) {
        options = {
          ...options,
          csvURL: link
        };
      }

      dispatch(
        loadLiveDataAction({
          options: options,
          isMap: isMap,
          isDataServer: false
        })
      );
    } else if (type === 'googleSpreadsheetKey') {
      const match = link.match(/https:\/\/docs.google.com\/spreadsheets\/d\/(.{44})\/edit(?:\?gid=(\d+))?#gid=(\d+)/);
      const newSpreadsheetKey = match ? match[1] : '';
      const newGid = match ? match[2] ?? match[3] : '0';
      const options = cloneDeep(aggregatedOptions?.data);

      if (newSpreadsheetKey) set(options, type, newSpreadsheetKey);
      if (newGid) {
        const newSheetName = await getSheetName(newSpreadsheetKey, newGid);
        if (newSheetName) set(options, 'googleSpreadsheetRange', `${newSheetName}!${range}`);
      }

      if (isTable) {
        dispatch(
          setDataAction({
            options: options,
            dataType: 'google',
            cb: stopLoading
          })
        );
      } else {
        dispatch(
          loadGoogleSheetAction({
            options: options,
            isDataServer: false,
            isMap: isMap
          })
        );
      }
    } else {
      const options = cloneDeep(aggregatedOptions?.data);
      if (link && type) set(options, type, link);
      dispatch(
        loadLiveDataAction({
          options: options,
          isDataServer: false
        })
      );
    }
  };

  return (
    <div className="pb-4 w-full">
      <div className="py-2 pr-4 relative text-base font-bold">Data linked from:</div>
      <div className="py-2">
        <InputWidget onChange={onChange} option={{}} value={link} />
      </div>

      <div className="flex">
        <PrimaryButton
          onClick={sync}
          useLoader={{
            default: {
              text: 'Refresh',
              icon: 'rotate'
            },
            loading: {
              text: 'Refreshing',
              check: isLoading
            }
          }}
          buttonColor={ButtonColor.NavyBlue}
          buttonType={ButtonType.Info}
          className="text-sm"
        />
        <TextButton onClick={loadModal} text={'Link new data'} className="ml-auto pt-2" />
      </div>
    </div>
  );
};

export default LiveSourceSection;
