(function (Highcharts) {
  if (!Highcharts) return;

  const MOBILE_SCREEN_WIDTH = 414;
  var arrowLength = 6;

  const markers = {};
  Highcharts.evervizMarkerTypes = [
    {
      id: 'evervizArrow',
      path: 'M 2 4 L 0 8 L 5 8 L 2 4 Z'
    },
    {
      id: 'evervizLeftArrow',
      path: 'M 2 4 L 1 8 L 5 7 L 1 3 Z'
    },
    {
      id: 'evervizStraightArrowHead',
      path: 'M 0 4 L 3 6 L 0 8 Z'
    },
    {
      id: 'evervizConnectorArrow',
      path: 'M 0 4 L 0 8 L 5 6 L 0 4 Z'
    }
  ];
  Highcharts.evervizMarkerDefaultSettings = {
    tagName: 'marker',
    children: [],
    markerWidth: 10,
    markerHeight: 10,
    refX: 2,
    refY: 6,
    orient: 'auto'
  };
  Highcharts.evervizMarkerTypes.forEach(function (type, index) {
    markers['marker' + index] = Highcharts.merge(Highcharts.merge({}, Highcharts.evervizMarkerDefaultSettings), {
      id: type.id,
      children: [
        {
          tagName: 'path',
          d: type.path
        }
      ]
    });
    return markers;
  });

  Highcharts.setOptions({
    defs: markers
  });

  function curvedLine(p1x, p1y, p2x, p2y, direction) {
    // mid-point of line:
    var mpx = (p2x + p1x) * 0.5;
    var mpy = (p2y + p1y) * 0.5;

    // angle of perpendicular to line:
    const theta = Math.atan2(p2y - p1y, p2x - p1x) + (Math.PI / 2) * direction;

    // distance of control point from mid-point of line:
    var offset = 10;

    // location of control point:
    var c1x = mpx + offset * Math.cos(theta);
    var c1y = mpy + offset * Math.sin(theta);

    // construct the command to draw a quadratic curve
    return ['Q', c1x, c1y, p2x, p2y];
  }

  Highcharts.Renderer.prototype.symbols.evervizConnector = function (x, y, w, h, options) {
    var anchorX = options && options.anchorX,
      anchorY = options && options.anchorY,
      path,
      yOffset,
      lateral = w / 2;

    var markerPrefix = '';

    var uniqueId;

    if (options.parentGroup.parentGroup) uniqueId = options.parentGroup.parentGroup.uniqueId;

    if (uniqueId) {
      markerPrefix = uniqueId + '-';
    }

    if (Highcharts.isNumber(anchorX) && Highcharts.isNumber(anchorY)) {
      path = [['M', anchorX, anchorY]];
      // Prefer 45 deg connectors
      yOffset = y - anchorY;
      if (yOffset < 0) {
        yOffset = -h - yOffset;
      }
      if (yOffset < w) {
        lateral = anchorX < x + w / 2 ? yOffset : w - yOffset;
      }
      // Anchor below label
      if (anchorY > y + h) {
        path.push(curvedLine(anchorX, anchorY, x + lateral, y + h, -1));
        path.push(curvedLine(x + lateral, y + h, anchorX, anchorY, 1));

        options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizConnectorArrow)');
        // Anchor above label
      } else if (anchorY < y) {
        path.push(curvedLine(anchorX, anchorY, x + lateral, y, -1));
        path.push(curvedLine(x + lateral, y, anchorX, anchorY, 1));
        options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizConnectorArrow)');
        // Anchor left of label
      } else if (anchorX < x) {
        path.push(curvedLine(anchorX, anchorY, x, y + h / 2, -1));
        path.push(curvedLine(x, y + h / 2, anchorX, anchorY, 1));
        options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizConnectorArrow)');
        // Anchor right of label
      } else if (anchorX > x + w) {
        path.push(curvedLine(anchorX, anchorY, x + w, y + h / 2, -1));
        path.push(curvedLine(x + w, y + h / 2, anchorX, anchorY, 1));
        options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizConnectorArrow)');
      }
    }
    return path || [];
  };

  Highcharts.Renderer.prototype.symbols.evervizCircle = function (x, y, w, h, options) {
    var anchorX = (options && options.anchorX) || 0, // ANCHORXY is where the line ends up
      anchorY = (options && options.anchorY) || 0,
      path,
      markerPrefix = '',
      textElement = options.parentGroup.text.element;

    w = h = 20;

    textElement.setAttribute('x', 6);
    textElement.setAttribute('y', 14);

    var uniqueId;
    if (options.parentGroup.parentGroup) uniqueId = options.parentGroup.parentGroup.uniqueId;

    if (uniqueId) {
      markerPrefix = uniqueId + '-';
    }

    path = this.arc(x + w / 2, y + h / 2, w / 2, h / 2, {
      start: Math.PI * 0.5,
      end: Math.PI * 2.5,
      open: false
    });

    const yCenter = y + h / 2,
      xCenter = x + w / 2,
      halfWidth = w / 2,
      halfHeight = h / 2;

    var B = Math.abs(yCenter - anchorY),
      A = Math.abs(anchorX - xCenter),
      C = Math.sqrt(Math.pow(A, 2) + Math.pow(B, 2));

    var cosAlpha,
      sinAlpha,
      xBuffer = 0,
      yBuffer = 0,
      //arrowType = 'url(#evervizArrow)';
      arrowType = 'url(#' + markerPrefix + 'evervizArrow)';

    if (anchorX < xCenter && anchorY < yCenter) {
      //topLeft
      (cosAlpha = -A / C), (sinAlpha = B / C);
      xBuffer = yBuffer = -1;
      //arrowType = 'url(#evervizLeftArrow)';
      arrowType = 'url(#' + markerPrefix + 'evervizLeftArrow)';
    } else if (anchorX > xCenter && anchorY < yCenter) {
      //topRight
      (cosAlpha = A / C), (sinAlpha = B / C);
      xBuffer = 1;
      yBuffer = -1;
    } else if (anchorX > xCenter && anchorY > yCenter) {
      //bottomRight
      (cosAlpha = A / C), (sinAlpha = -B / C);
      xBuffer = yBuffer = 1;
    } else if (anchorX < xCenter && anchorY > yCenter) {
      //bottomLeft
      (cosAlpha = -A / C), (sinAlpha = -B / C);
      xBuffer = -1;
      yBuffer = 1;
    }

    const xT = (w / 2 + x) * cosAlpha;
    const yT = (h / 2 + y) * sinAlpha;
    const xCirclePos = xT + halfWidth + xBuffer;
    const yCirclePos = -yT + halfHeight + yBuffer;
    const diffX = Math.abs(xCirclePos - anchorX);
    const diffY = Math.abs(yCirclePos - anchorY);

    //Dont want to add a line on points that dont need it.
    if (diffX <= 10 && diffY <= 10) {
      options.element.setAttribute('marker-end', '');
      return path;
    }

    if (diffX <= 20 && diffY <= 20) {
      arrowType = 'url(#' + markerPrefix + 'evervizStraightArrowHead)';
      path.splice(3, 1, ['M', xCirclePos, yCirclePos], ['L', anchorX, anchorY], ['Z'], ['M', anchorX, anchorY]);
    } else {
      options.element.setAttribute('marker-end', arrowType);
      path.splice(
        3,
        1,
        ['M', xCirclePos, yCirclePos],
        curvedLine(xCirclePos, yCirclePos, anchorX, anchorY, -1),
        curvedLine(anchorX, anchorY, xCirclePos, yCirclePos, 1),
        ['Z'],
        ['M', anchorX, anchorY]
      );
    }

    return path;
  };

  function calloutBottomStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix) {
    path.push(['Z'], ['M', (x + w) / 2, y + h + arrowLength], ['L', anchorX, anchorY], ['Z'], ['M', anchorX, anchorY]);

    options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizStraightArrowHead)');
  }
  function calloutTopStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix) {
    path.push(['Z'], ['M', (x + w) / 2, y - arrowLength], ['L', anchorX, anchorY], ['Z'], ['M', anchorX, anchorY]);

    options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizStraightArrowHead)');
  }
  function calloutLeftStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix) {
    path.splice(7, 1, ['L', x, h / 2], ['L', anchorX, anchorY], ['L', x, h / 2]);
    path.push(['M', anchorX, anchorY]);
    options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizLeftArrow)');
  }
  function calloutLeftCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix) {
    path.splice(
      7,
      1,
      ['L', x, h / 2],
      curvedLine(x, h / 2, anchorX, anchorY, -1),
      curvedLine(anchorX, anchorY, x, h / 2, 1)
    );
    path.push(['M', anchorX, anchorY]);
    options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizLeftArrow)');
  }
  function calloutRightStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix) {
    path.splice(3, 1, ['L', x + w, (y + h) / 2], ['L', anchorX, anchorY], ['L', x + w, (y + h) / 2]);
    path.push(['M', anchorX, anchorY]);
    options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizStraightArrowHead)');
  }
  function calloutRightCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix) {
    path.splice(
      3,
      1,
      ['L', x + w, (y + h) / 2],
      curvedLine(x + w, (y + h) / 2, anchorX, anchorY, -1),
      curvedLine(anchorX, anchorY, x + w, (y + h) / 2, 1)
    );
    path.push(['M', anchorX, anchorY]);
    options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizArrow)');
  }

  Highcharts.Renderer.prototype.symbols.evervizCallout = function (x, y, w, h, options) {
    var halfDistance = 6,
      r = Math.min((options && options.r) || 0, w, h),
      safeDistance = r + halfDistance,
      anchorX = (options && options.anchorX) || 0,
      anchorY = (options && options.anchorY) || 0,
      path,
      markerPrefix = '',
      text = options?.parentGroup?.text;
    const textElement = text?.element;

    // Highcharts doesnt always pass in the correct width which then cuts off the text label because of it.
    // Check the label width (with a buffer) fits within bounds.
    if (text && textElement?.getBBox) {
      const textBbox = textElement.getBBox();
      const textWidth = textBbox.width + textBbox.x;
      const bufferWidth = 5;
      if (textWidth + bufferWidth > w) {
        w += textWidth + bufferWidth - w;
      }
    }

    var uniqueId;
    if (options.parentGroup.parentGroup) uniqueId = options.parentGroup.parentGroup.uniqueId;

    if (uniqueId) {
      markerPrefix = uniqueId + '-';
    }

    path = [
      ['M', x + r, y],
      ['L', x + w - r, y], // top side
      ['C', x + w, y, x + w, y, x + w, y + r], // top-right corner
      ['L', x + w, y + h - r], // right side
      ['C', x + w, y + h, x + w, y + h, x + w - r, y + h], // bottom-rgt
      ['L', x + r, y + h], // bottom side
      ['C', x, y + h, x, y + h, x, y + h - r], // bottom-left corner
      ['L', x, y + r], // left side
      ['C', x, y, x, y, x + r, y] // top-left corner
    ];

    // Anchor on right side
    if (anchorX && anchorX > w) {
      if (Math.abs(anchorX - (x + w)) < 10) {
        if (anchorY > y + h) {
          //at bottom
          if (
            Math.abs(anchorY - (y + h)) < 5 ||
            (Math.abs(anchorX - (x + w)) <= 10 && Math.abs(anchorY - (y + h)) <= 10.5)
          )
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - (y + h)) < 10)
            calloutBottomStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else if (Math.abs(anchorY - (y + h)) > 10)
            calloutRightCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else if (anchorY <= y) {
          //at top
          if (Math.abs(anchorY - y) < 5 || (Math.abs(anchorX - (x + w)) <= 10 && Math.abs(anchorY - y) <= 10.5))
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - y) < 11)
            calloutTopStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else if (Math.abs(anchorY - y) > 10)
            calloutRightCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else options.element.setAttribute('marker-end', '');
      } else if (Math.abs(anchorX - (x + w)) < 20) {
        if (anchorY > y + h) {
          //at bottom
          if (
            Math.abs(anchorY - (y + h)) < 5 ||
            (Math.abs(anchorX - (x + w)) <= 20 && Math.abs(anchorY - (y + h)) <= 10.5)
          )
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - (y + h)) < 10) options.element.setAttribute('marker-end', '');
          //calloutBottomStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else calloutRightCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else if (anchorY <= y) {
          //at top
          if (Math.abs(anchorY - y) < 5 || (Math.abs(anchorX - (x + w)) <= 20 && Math.abs(anchorY - y) <= 10.5))
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - y) < 11)
            calloutTopStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else calloutRightCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else calloutRightStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
      } else calloutRightCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);

      // Anchor on left side
    } else if (anchorX && anchorX < 0) {
      if (Math.abs(anchorX - x) < 10) {
        if (anchorY > y + h) {
          //at bottom
          if (Math.abs(anchorY - (y + h)) < 5 || (Math.abs(anchorX - x) <= 10 && Math.abs(anchorY - (y + h)) <= 10.5))
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - (y + h)) < 10)
            calloutBottomStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else if (Math.abs(anchorY - (y + h)) > 10)
            calloutLeftCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else if (anchorY <= y) {
          //at top
          if (Math.abs(anchorY - y) < 5 || (Math.abs(anchorX - x) <= 10 && Math.abs(anchorY - y) <= 10.5))
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - y) < 11)
            calloutTopStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else if (Math.abs(anchorY - y) > 10)
            calloutLeftCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else options.element.setAttribute('marker-end', '');
      } else if (Math.abs(anchorX - x) < 20) {
        if (anchorY > y + h) {
          //at bottom
          if (Math.abs(anchorY - (y + h)) < 5 || (Math.abs(anchorX - x) <= 20 && Math.abs(anchorY - (y + h)) <= 10.5))
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - (y + h)) < 10) options.element.setAttribute('marker-end', '');
          //calloutBottomStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else calloutLeftCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else if (anchorY <= y) {
          //at top
          if (Math.abs(anchorY - y) < 5 || (Math.abs(anchorX - x) <= 20 && Math.abs(anchorY - y) <= 10.5))
            options.element.setAttribute('marker-end', '');
          else if (Math.abs(anchorY - y) < 11)
            calloutTopStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
          else calloutLeftCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
        } else calloutLeftStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
      } else calloutLeftCurve(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
    } else if (
      // replace bottom
      anchorY &&
      anchorY > h &&
      anchorX >= x &&
      anchorX <= x + w
    ) {
      if (Math.abs(anchorY - (y + h + arrowLength)) < 10) {
        options.element.setAttribute('marker-end', '');
      } else if (Math.abs(anchorY - (y + h + arrowLength)) < 20) {
        calloutBottomStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
      } else if (Math.abs(anchorY - (y + h + arrowLength)) >= 20) {
        path.push(
          ['Z'],
          ['M', (x + w) / 2, y + h + arrowLength],
          curvedLine((x + w) / 2, y + h + arrowLength, anchorX, anchorY, -1),
          curvedLine(anchorX, anchorY, (x + w) / 2, y + h + arrowLength, 1),
          ['Z'],
          ['M', anchorX, anchorY]
        );
        options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizArrow)');
      } else {
        options.element.setAttribute('marker-end', '');
      }
    } else if (
      // replace top
      anchorY &&
      anchorY < 0 &&
      anchorX >= x &&
      anchorX <= x + w
    ) {
      if (Math.abs(anchorY - (y - arrowLength)) < 10) {
        options.element.setAttribute('marker-end', '');
      } else if (Math.abs(anchorY - (y - arrowLength)) < 20) {
        calloutTopStraight(path, anchorX, anchorY, x, y, w, h, options, markerPrefix);
      } else if (Math.abs(anchorY - (y - arrowLength)) >= 20) {
        path.push(
          ['Z'],
          ['M', (x + w) / 2, y - arrowLength],
          curvedLine((x + w) / 2, y - arrowLength, anchorX, anchorY, -1),
          curvedLine(anchorX, anchorY, (x + w) / 2, y - arrowLength, 1),
          ['Z'],
          ['M', anchorX, anchorY]
        );
        options.element.setAttribute('marker-end', 'url(#' + markerPrefix + 'evervizArrow)');
      } else options.element.setAttribute('marker-end', '');
    } else {
      options.element.setAttribute('marker-end', '');
    }

    return path;
  };

  function loadSmallScreenAnnotations(chart, annotations, container, annotationProps, isLoad) {
    if (chart.smallScreenContainer) {
      chart.smallScreenContainer.innerHTML = '';
    } else {
      chart.smallScreenContainer = chart.smallScreenContainer = document.createElement('div');
      chart.smallScreenContainer.className = 'highcharts-small-screen-annotations-container';
      container.appendChild(chart.smallScreenContainer);
    }

    let smallScreenContainer = chart.smallScreenContainer;

    chart.showingSmallAnnotations = true;
    var labelCounter = 1;
    annotations.forEach(function (annotation, index) {
      if (annotation.labels && annotation.labels[0]) {
        if (annotation.labels[0]?.options?.everviz?.text?.showKeyOnMobile?.enabled === false) {
          labelCounter++;
        } else {
          const node = document.createElement('div');
          const circleNode = document.createElement('span');
          var circleStyling = {
              backgroundColor: annotation.labels[0].options.backgroundColor || 'black',
              borderColor: annotation.labels[0].options.backgroundColor || 'black',
              display: 'flex',
              alignItems: 'center'
            },
            circleNumberStyling = {
              color: annotation.labels[0].options.style.color || 'white'
            };

          const shapeType = annotation.userOptions.labels[0].shape;

          const nodeNumber = document.createElement('div');
          nodeNumber.className = 'highcharts-number-circle-number';
          nodeNumber.innerHTML = labelCounter;

          circleNode.className = 'highcharts-number-circle';
          circleNode.appendChild(nodeNumber);

          annotationProps[index] = {
            shape: annotation.userOptions.labels[0].shape || 'evervizCallout',
            format: annotation.labels[0].graphic.text.textStr
          };

          if (window['Everviz'] && window['Everviz'].evervizEditor) {
            //We're in the editor, not the publish codes
            if (isLoad) {
              annotationProps[index] = Object.assign(
                Object.assign({}, annotationProps[index]),
                Object.assign({}, window['Everviz'].evervizEditor.chart.annotations.getPersistentAnnotationProps(index))
              );
            } else {
              annotationProps[index] = Object.assign(
                {},
                window['Everviz'].evervizEditor.chart.annotations.setPersistentAnnotationProps(index, {
                  shape: annotation.userOptions.labels[0].shape || 'evervizCallout',
                  format: annotation.labels[0].graphic.text.textStr
                })
              );
            }
          }

          var updatedOptions = {
            labels: [
              {
                format: '' + labelCounter,
                shape: 'evervizCircle',
                markerEnd: 'evervizArrow'
              }
            ]
          };

          if (shapeType === 'evervizConnector') {
            annotationProps[index].style = {
              color: annotation.labels[0].options.style.color
            };
            updatedOptions.labels[0].style = {
              color: 'white'
            };

            Object.assign(circleNumberStyling, {
              color: 'white'
            });
          }

          if (
            annotation.labels[0].options &&
            annotation.labels[0].options.style &&
            annotation.labels[0].options.style.fontSize
          ) {
            if (!annotationProps[index].style) annotationProps[index].style = {};
            if (!updatedOptions.labels[0].style) updatedOptions.labels[0].style = {};
            annotationProps[index].style.fontSize = annotation.labels[0].options.style.fontSize;
            updatedOptions.labels[0].style.fontSize = '11px';
          }

          Object.assign(nodeNumber.style, circleNumberStyling);
          Object.assign(circleNode.style, circleStyling);
          node.className = 'highcharts-annotation-node';
          node.style.display = 'flex';
          node.style.alignItems = 'center';

          node.appendChild(circleNode);
          node.innerHTML += annotationProps[index].format;
          annotation.labels[0].graphic.innerHTML = '' + labelCounter;
          smallScreenContainer.appendChild(node);

          annotation.update(updatedOptions);
          annotation.labelsGroup.uniqueId = annotation.labels[0].options.uniqueId;
          labelCounter++;

          if (chart && chart.options) {
            var yaxis = chart.options.yAxis;
            var xaxis = chart.options.xAxis;
            if (yaxis && yaxis.constructor === Array) yaxis = yaxis[0];
            if (xaxis && xaxis.constructor === Array) xaxis = xaxis[0];

            if (xaxis && xaxis.labels && xaxis.labels.style && xaxis.labels.style.color)
              node.style.color = xaxis.labels.style.color;
            else if (yaxis && yaxis.labels && yaxis.labels.style && yaxis.labels.style.color)
              node.style.color = yaxis.labels.style.color;
          }
        }
      }
    });

    chart.previousSpacingBottom = smallScreenContainer.clientHeight + 30;

    if (chart.mapNavigation && chart.mapNavigation.length) {
      if (chart.container) {
        const zoomOutButton = chart.container.querySelector('.highcharts-map-navigation.highcharts-zoom-out');
        const zoomInButton = chart.container.querySelector('.highcharts-map-navigation.highcharts-zoom-in');
        if (zoomOutButton) zoomOutButton.style.display = 'none';
        if (zoomInButton) zoomInButton.style.display = 'none';
      }
      chart.update(
        {
          chart: {
            spacingBottom: smallScreenContainer.clientHeight + 30
          }
        },
        true,
        false,
        false
      );
    } else {
      chart.update(
        {
          chart: {
            spacingBottom: smallScreenContainer.clientHeight + 30
          }
        },
        true,
        false,
        false
      );
      if (chart.container) {
        const zoomOutButton = chart.container.querySelector('.highcharts-map-navigation.highcharts-zoom-out');
        const zoomInButton = chart.container.querySelector('.highcharts-map-navigation.highcharts-zoom-in');
        if (zoomOutButton) zoomOutButton.style.display = 'unset';
        if (zoomInButton) zoomInButton.style.display = 'unset';
      }
    }

    Object.assign(smallScreenContainer.style, {
      position: 'absolute',
      left: '10px',
      bottom: '10px'
    });
  }

  Highcharts.addEvent(Highcharts.Chart, 'load', function () {
    const annotations = this.annotations || [];
    const renderer = this.renderer,
      container = this.container,
      chartWidth = this.chartWidth;

    if (!annotations.length) return;

    this.previousChartWidth = chartWidth;
    if (!this.annotationProps) this.annotationProps = [];
    if (chartWidth <= MOBILE_SCREEN_WIDTH) {
      loadSmallScreenAnnotations(this, annotations, container, this.annotationProps, true);
    }

    //Add default markers
    Highcharts.evervizMarkerTypes.forEach((markerType) => {
      const predefinedOption = Highcharts.merge(Highcharts.merge({}, Highcharts.evervizMarkerDefaultSettings), {
        id: markerType.id,
        children: [
          {
            tagName: 'path',
            d: markerType.path
          }
        ]
      });

      renderer.addMarker(
        markerType.id,
        Highcharts.merge(predefinedOption, {
          color: 'black'
        })
      );
    });

    annotations.forEach(function (annotation) {
      if (annotation.labels && annotation.labels[0]) {
        const uniqueId = Highcharts.uniqueKey();
        Highcharts.evervizMarkerTypes.forEach((markerType) => {
          const predefinedOption = Highcharts.merge(Highcharts.merge({}, Highcharts.evervizMarkerDefaultSettings), {
            id: markerType.id,
            children: [
              {
                tagName: 'path',
                d: markerType.path
              }
            ]
          });
          renderer.addMarker(
            uniqueId + '-' + markerType.id,
            Highcharts.merge(predefinedOption, {
              color: annotation.labels[0].options.backgroundColor || 'black'
            })
          );
          annotation.labelsGroup.uniqueId = uniqueId;
          annotation.labels[0].annotation.update({
            uniqueId: uniqueId
          });
          annotation.labelsGroup.uniqueId = uniqueId;
          annotation.redraw();
        });
      }
    });
  });

  Highcharts.addEvent(Highcharts.Chart, 'render', function () {
    const annotations = this.annotations || [],
      container = this.container,
      chartWidth = this.chartWidth;

    if (!annotations.length) return;

    if (!this.annotationProps) this.annotationProps = [];
    let annotationProps = this.annotationProps;

    if (this.previousChartWidth !== chartWidth) {
      this.previousChartWidth = chartWidth;
      if (chartWidth <= MOBILE_SCREEN_WIDTH) {
        if (this.showingSmallAnnotations) return;
        loadSmallScreenAnnotations(this, annotations, container, annotationProps);
      } else {
        if (this.smallScreenContainer) this.smallScreenContainer.innerHTML = '';
        this.showingSmallAnnotations = false;

        annotations.forEach(function (annotation, index) {
          if (annotation.labels && annotation.labels[0]) {
            annotation.update({
              labels: [annotationProps[index]]
            });
            annotation.labelsGroup.uniqueId = annotation.labels[0].options.uniqueId;
            annotation.redraw();
          }
        });

        if (this.previousSpacingBottom >= 15) {
          this.previousSpacingBottom = 15;

          this.update(
            {
              chart: {
                spacingBottom: 15
              }
            },
            true,
            false,
            false
          );
        }
      }
    }
  });
})(window['Highcharts']);
