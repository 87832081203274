export interface SubscriptionPlan {
  id: number;
  value: number;
  price: number;
  name: string;
  text?: string;
  interval_length?: number;
  period_end_time?: string;
}

export enum SubscriptionPlans {
  Free = 'Free',
  Starter = 'Starter',
  Business = 'Business',
  Pro = 'Pro',
  Enterprise = 'Enterprise',
  Storage = 'Storage',
  ProTrial = 'Pro Trial',
  Churned = 'Churned'
}

export enum PlanPeriod {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export interface BillingField {
  value: string;
  error: string;
  name: string;
  placeholder?: string;
  label?: string;
  type?: React.HTMLInputTypeAttribute;
}

export type BillingForm = Record<string, BillingField>;

export enum AccountFormSetup {
  Register = 'Register account',
  Account = 'Account'
}

export type PlanFeature = {
  text: string;
  link?: string;
  anchorLink?: string;
};
