import { SearchedOption } from '../components/linkingData/DataServerStocks';
import StarIcon from 'static/icons/star.svg';

export enum LinkDataOption {
  Refinitiv = 'refinitiv',
  GoogleSheet = 'gsheet',
  CSV = 'csvURL',
  JSONColumn = 'columnsURL',
  JSONRow = 'rowsURL',
  Polygon = 'polygon'
}

export enum StockTimePeriod {
  Day = '1D',
  FiveDays = '5D',
  Month = '1M',
  SixMonths = '6M',
  YearToDate = 'YTD',
  Year = '1Y',
  FiveYears = '5Y',
  Max = 'MAX'
}

export enum PerformanceTimePeriod {
  ThreeYears = '3Y',
  FiveYears = '5Y',
  TenYears = '10Y',
  FifteenYears = '15Y',
  All = 'All'
}

export enum Markets {
  All = 'All',
  US = 'US',
  Europe = 'Europe',
  Asia = 'Asia',
  Currencies = 'Currencies'
}

export enum PolygonMarkets {
  All = 'All',
  Currencies = 'Currencies'
}

export enum StockDataType {
  Pricing = 'Pricing',
  Performance = 'Performance'
}

export const linkDataOptions = [
  { value: LinkDataOption.GoogleSheet, label: 'Google sheet' },
  { value: LinkDataOption.CSV, label: 'CSV' },
  { value: LinkDataOption.JSONColumn, label: 'JSON (column ordered)' },
  { value: LinkDataOption.JSONRow, label: 'JSON (row ordered)' },
  { value: LinkDataOption.Refinitiv, label: 'Refinitiv', isPaidFeature: true, lockIcon: StarIcon },
  { value: LinkDataOption.Polygon, label: 'Polygon', isPaidFeature: true, lockIcon: StarIcon }
];

export const linkDataOptionsForMap = [
  { value: LinkDataOption.GoogleSheet, label: 'Google sheet' },
  { value: LinkDataOption.CSV, label: 'CSV' }
];

export const dataServerOptions = [LinkDataOption.Refinitiv, LinkDataOption.Polygon];

export const refreshRateOptions = [
  { label: 'None', value: '' },
  { label: '10 second', value: '10' },
  { label: '30 seconds', value: '30' },
  { label: '1 minute', value: '60' },
  { label: '5 minutes', value: '300' },
  { label: '10 minutes', value: '600' },
  { label: '12 hours', value: '43200' }
];

export type LinkDataTypes = 'google' | 'live' | LinkDataOption.Refinitiv | LinkDataOption.Polygon;

export type ChartLiveDataProps = {
  dataRefreshRate?: number;
  enablePolling?: boolean;
  csvURL?: string;
  rowsURL?: string;
  columnsURL?: string;
  googleSpreadsheetKey?: string;
  googleSpreadsheetRange?: string;
  symbols?: SearchedOption[];
  timePeriod?: StockTimePeriod | PerformanceTimePeriod;
  provider?: string;
  stockDataType?: string;
};
