import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectLocationMap } from 'redux/reducers/locationMap/instanceReducer';
import { ProjectConfigLocationMapProps } from '../../../../pages/Editor/reducers/locationMapConfigTypes';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import { SectionNode } from '../SectionNode';
import { useSectionStore } from '../sectionStore';
import { MarkerSection } from './MarkerSection';

export type MarkersSectionProps = {
  showContainer?: boolean;
  openedByDefault?: boolean;
};
const sectionKey = 'markers';

export const MarkersSection = ({ showContainer, openedByDefault }: MarkersSectionProps) => {
  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);
  const locationMap = useSelector(selectLocationMap);

  const featureAmount = aggregatedOptions.markers.length;

  const setSelectedMarkerSection = useSectionStore((state) => state.setSelectedSection);

  useEffect(() => {
    if (!locationMap) return;
    const onMarkerClick = (id: string) => {
      setSelectedMarkerSection(id);
    };

    locationMap.on('markerClick', onMarkerClick);

    return () => {
      locationMap.off('markerClick', onMarkerClick);
    };
  }, [locationMap]);

  const childrenSectionKeys = useMemo(() => {
    return aggregatedOptions.markers.map((x) => x.id);
  }, [aggregatedOptions.markers]);

  return (
    <SectionNode
      showContainer={showContainer}
      sectionKey={sectionKey}
      childrenSectionKeys={childrenSectionKeys}
      headerText="Markers"
      openedByDefault={openedByDefault}
      selectedChildren={featureAmount.toString()}
    >
      <div className="flex flex-col gap-2">
        {aggregatedOptions.markers.length > 0 ? (
          aggregatedOptions.markers.map((marker) => {
            return (
              <MarkerSection key={marker.id} sectionKey={marker.id} marker={marker} parentSectionKey={sectionKey} />
            );
          })
        ) : (
          <div className="text-sm">No markers have been added yet. Begin adding markers to customize your map.</div>
        )}
      </div>
    </SectionNode>
  );
};
