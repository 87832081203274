import { linkExternalDataAction } from 'pages/ChartEditorPage/actions/chartEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { customStyles } from 'shared/utils/selectStylesHelper';
import {
  ChartLiveDataProps,
  LinkDataOption,
  dataServerOptions,
  linkDataOptions,
  linkDataOptionsForMap,
  refreshRateOptions
} from 'shared/wizard/meta/LinkDataOptions';
import { shouldDisplayDataServerOptionForType } from 'shared/wizard/utils/linkDataHelper';
import PrimaryButton from '../../../buttons/PrimaryButton';
import CustomSelect, { SelectItem } from '../../../inputs/CustomSelect';
import { openPremiumFeatureModalAction } from 'redux/actions/app';
import Modal from '../../../modal/Modal';
import GoogleSheetContent from './GoogleSheetContent';
import RefinitivContent from './RefinitivContent';
import URLOnlyContent from './URLOnlyContent';
import PolygonContent from './PolygonContent';

type LinkDataModalProps = {
  onHasData: (options: unknown, dataType: string) => void;
  isChart: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export default function LinkDataModal(props: LinkDataModalProps) {
  const [filteredOptions, setFilteredOptions] = useState(linkDataOptions);
  const [linkDataType, setLinkDataType] = useState<SelectItem>(filteredOptions[0]);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [refreshRate, setRefreshRate] = useState<SelectItem>({ label: 'None', value: '' });
  const [loadingData, setLoadingData] = useState(false);
  const [errorsComponent, setErrorsComponent] = useState<React.ReactNode>(null);
  const [shouldDisplaySummary, setShouldDisplaySummary] = useState(true);

  const dispatch = useDispatch();

  const { chosenWizardTemplate, isMap } = useSelector<any, any>((state: RootState) => state.chartEditorPage);
  const { parent: chartType } = chosenWizardTemplate;
  const { subscription } = useSelector((state: RootState) => state.profile);
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const isTable = type === 'table';

  useEffect(() => {
    if (shouldSubmit) setShouldSubmit(false);
  }, [shouldSubmit]);

  useEffect(() => {
    if (isMap) {
      setFilteredOptions(linkDataOptionsForMap);
    }
  }, [isMap]);

  useEffect(() => {
    const canAccessDataServerData = (subscription?.team_features ?? []).includes(2);
    if (!canAccessDataServerData && !isTable && !isMap) {
      setFilteredOptions(
        linkDataOptions.map((option) => (option.isPaidFeature ? { ...option, isLocked: true } : option))
      );
    }
  }, [subscription?.team_features]);

  useEffect(() => {
    if (!dataServerOptions.includes(linkDataType.value as LinkDataOption)) {
      setShouldDisplaySummary(true);
    }
  }, [linkDataType]);

  useEffect(() => {
    const canAccessDataServerData = (subscription?.team_features ?? []).includes(2);
    if (canAccessDataServerData && !isTable && !isMap) {
      setFilteredOptions((prev) =>
        prev.filter((item) => dataServerOptions.includes(item.value) || shouldDisplayDataServerOptionForType(chartType))
      );
    }
  }, [chartType]);

  useEffect(() => {
    setLinkDataType(filteredOptions[0]);
  }, [filteredOptions]);

  const handleSubmit = (options: ChartLiveDataProps, dataType: string) => {
    setErrorsComponent(null);
    if (dataServerOptions.includes(dataType as LinkDataOption)) {
      dispatch(
        linkExternalDataAction({
          ...options,
          provider: dataType,
          dataRefreshRate: refreshRate.value,
          enablePolling: Boolean(refreshRate.value)
        })
      );
      props.onClose();
      return;
    }

    props.onHasData(
      {
        ...options,
        dataRefreshRate: refreshRate.value,
        enablePolling: Boolean(refreshRate.value)
      },
      dataType
    );
  };

  const selectLinkDataType = (selected: SelectItem) => {
    if (selected?.isLocked) {
      dispatch(openPremiumFeatureModalAction({ premiumFeatureName: selected.label }));
      props.onClose();
    } else setLinkDataType(selected as SelectItem);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Link data"
      width="w-7/12 max-w-xl"
      bg="bg-ev-yellow-1"
      bodyClass="px-8 py-6 text-left"
      allowOverflow={true}
      id="ev-link-data-modal"
    >
      <p className="mb-4">Connect your visualization to a live data source</p>
      {props.isChart && (
        <div className="pb-4">
          <CustomSelect
            styles={customStyles}
            label="Data connector:"
            options={filteredOptions}
            onChange={(selected) => selectLinkDataType(selected as SelectItem)}
            value={linkDataType}
          />
        </div>
      )}

      {(linkDataType.value === LinkDataOption.CSV ||
        linkDataType.value === LinkDataOption.JSONColumn ||
        linkDataType.value === LinkDataOption.JSONRow) && (
        <URLOnlyContent
          activeOptionValue={linkDataType.value}
          handleSubmit={handleSubmit}
          shouldSubmit={shouldSubmit}
        />
      )}
      {linkDataType.value === LinkDataOption.GoogleSheet && (
        <GoogleSheetContent
          handleSubmit={handleSubmit}
          shouldSubmit={shouldSubmit}
          setLoading={setLoadingData}
          setErrorsComponent={setErrorsComponent}
        />
      )}
      {linkDataType.value === LinkDataOption.Refinitiv && (
        <RefinitivContent
          setShouldDisplaySummary={setShouldDisplaySummary}
          shouldSubmit={shouldSubmit}
          handleSubmit={handleSubmit}
          onModalClose={() => {
            setErrorsComponent(null);
            props.onClose();
          }}
          setErrorsComponent={setErrorsComponent}
        />
      )}
      {linkDataType.value === LinkDataOption.Polygon && (
        <PolygonContent
          setShouldDisplaySummary={setShouldDisplaySummary}
          shouldSubmit={shouldSubmit}
          handleSubmit={handleSubmit}
          onModalClose={() => {
            setErrorsComponent(null);
            props.onClose();
          }}
          setErrorsComponent={setErrorsComponent}
        />
      )}

      {shouldDisplaySummary && (
        <>
          <hr />
          {props.isChart && (
            <>
              <div className="font-bold text-lg text-ev-navy-blue-2 pb-4">Live data (optional)</div>
              <div className="pb-4">
                Live data means that the published chart always load the latest version of data from your url. Refresh
                time indicates how long after a user has loaded your site it&apos;ll refresh.
              </div>
              <div className="pb-6">
                <CustomSelect
                  styles={customStyles}
                  label={'Refresh time'}
                  options={refreshRateOptions}
                  onChange={(selected) => {
                    if (selected) setRefreshRate(selected);
                  }}
                  value={refreshRate}
                />
              </div>
              <hr />
            </>
          )}
          <div className="highed-button-container pt-6 flex gap-2">
            <PrimaryButton
              onClick={() => setShouldSubmit(true)}
              useLoader={{
                default: { text: 'Connect my data' },
                loading: { text: 'Connect my data', icon: 'rotate', check: loadingData }
              }}
              id="ev-link-data-submit"
            />
            <PrimaryButton onClick={props.onClose} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
          </div>
        </>
      )}
      {errorsComponent}
    </Modal>
  );
}
