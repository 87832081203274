(function (Highcharts) {
  const domains = [
    'https://app.everviz.com',
    'http://127.0.0.1:3000',
    'https://app-dev.everviz.com',
    'https://localhost:3000'
  ];

  /** Parse CSV Data
   * @namespace highed
   * @param {string} - inData
   * @return {string} - delimiter
   */
  Highcharts.Data.prototype.evervizGetCSVData = function (inData) {
    var csv = inData || '',
      result = [],
      options = {
        delimiter: null
      },
      potentialDelimiters = {
        ',': true,
        ';': true,
        '\t': true
      },
      delimiterCounts = {
        ',': 0,
        ';': 0,
        '\t': 0
      },
      rows;
    //The only thing CSV formats have in common..
    rows = (csv || '').replace(/\r\n/g, '\n').split('\n');
    // If there's no delimiter, look at the first few rows to guess it.
    if (!options.delimiter) {
      options.delimiter = this.evervizGuessDataDelimiter(rows);
    }
    rows.forEach(function (row, rowNumber) {
      var cols = [],
        columnCount,
        inStr = false,
        i = 0,
        j,
        token = '',
        guessedDel,
        c,
        cp,
        cn;

      function pushToken() {
        columnCount = columnCount + 1 || 0;
        //Dont know why this line is even in here tbh
        //token = (token || '').replace(/\,/g, '');
        if (!token.length) {
          token = null;
          // return;
        }
        if (Highcharts.isNumber(token)) {
          token = parseFloat(token);
        }
        cols.push(token);
        token = '';
      }
      for (i = 0; i < row.length; i++) {
        c = row[i];
        cn = row[i + 1];
        cp = row[i - 1];
        if (c === '"') {
          if (inStr) {
            inStr = false;
            //pushToken();
          } else {
            inStr = true;
          }
          //Everything is allowed inside quotes
        } else if (inStr) {
          token += c;
          //Check if we're done reading a token
        } else if (c === options.delimiter) {
          pushToken();
          //Append to token
        } else {
          token += c;
        }
        // Push if this was the last character
        if (i === row.length - 1) {
          pushToken();
        }
      }
      result.push(cols);
    });
    return result;
  };
  Highcharts.Data.prototype.evervizGuessDataDelimiter = function (lines) {
    var points = 0,
      commas = 0,
      guessed = false;
    var potDelimiters = {
      ',': true,
      ';': true,
      '\t': true
    };
    var delimiterCounts = {
      ',': 0,
      ';': 0,
      '\t': 0
    };
    lines.some(function (columnStr, i) {
      var inStr = false,
        c,
        cn,
        cl,
        token = '';
      // We should be able to detect dateformats within 13 rows
      if (i > 10) {
        return true;
      }
      for (var j = 0; j < columnStr.length; j++) {
        c = columnStr[j];
        cn = columnStr[j + 1];
        cl = columnStr[j - 1];
        if (c === '#') {
          // Skip the rest of the line - it's a comment
          return;
        }
        if (c === '"') {
          if (inStr) {
            if (cl !== '"' && cn !== '"') {
              while (cn === ' ' && j < columnStr.length) {
                cn = columnStr[++j];
              }
              // After parsing a string, the next non-blank
              // should be a delimiter if the CSV is properly
              // formed.
              if (typeof potDelimiters[cn] !== 'undefined') {
                potDelimiters[cn]++;
              }
              inStr = false;
            }
          } else {
            inStr = true;
          }
        } else if (typeof potDelimiters[c] !== 'undefined') {
          token = token.trim();
          if (!isNaN(Date.parse(token))) {
            potDelimiters[c]++;
          } else if (isNaN(token) || !isFinite(token) || /^\d+$/.test(token)) {
            potDelimiters[c]++;
          }
          token = '';
        } else {
          token += c;
        }
        if (c === ',') {
          commas++;
        }
        if (c === '.') {
          points++;
        }
      }
    });
    // Count the potential delimiters.
    // This could be improved by checking if the number of delimiters
    // equals the number of columns - 1
    if (potDelimiters[','] > potDelimiters[';'] && potDelimiters[','] > potDelimiters['\t']) {
      guessed = ',';
    } else if (potDelimiters['\t'] >= potDelimiters[';'] && potDelimiters['\t'] >= potDelimiters[',']) {
      guessed = '\t';
    } else {
      guessed = ';';
    }
    // Try to deduce the decimal point if it's not explicitly set.
    // If both commas or points is > 0 there is likely an issue
    /*
    if (!options.decimalPoint) {
        if (points > commas) {
            options.decimalPoint = '.';
        }
        else {
            options.decimalPoint = ',';
        }
        // Apply a new decimal regex based on the presumed decimal sep.
        self.decimalRegex = new RegExp('^(-?[0-9]+)' +
            options.decimalPoint +
            '([0-9]+)$');
    }*/
    return guessed;
  };
  Highcharts.wrap(Highcharts.Data.prototype, 'fetchLiveData', function (proceed) {
    var data = this,
      chart = this.chart,
      options = this.options,
      maxRetries = 3,
      currentRetries = 0,
      pollingEnabled = options.enablePolling,
      updateIntervalMs = (options.dataRefreshRate || 2) * 1000,
      originalOptions = Highcharts.merge(options),
      evervizDataOptions = Highcharts.merge(this.chartOptions?.everviz?.data ?? {});

    function hasURLOption(options) {
      return Boolean(options && (options.rowsURL || options.csvURL || options.columnsURL));
    }

    if (!hasURLOption(options)) {
      return false;
    }
    // Do not allow polling more than once a second
    if (updateIntervalMs < 1000) {
      updateIntervalMs = 1000;
    }
    delete options.csvURL;
    delete options.rowsURL;
    delete options.columnsURL;
    /**
     * @private
     */
    function performFetch(initialFetch) {
      /**
       * Helper function for doing the data fetch + polling.
       * @private
       */
      function request(url, done, tp) {
        if (!url || !/^(http|\/|\.\/|\.\.\/)/.test(url)) {
          if (url && options.error) {
            options.error('Invalid URL');
          }
          return false;
        }
        if (initialFetch) {
          clearTimeout(data.liveDataTimeout);
          chart.liveDataURL = url;
        }
        /**
         * @private
         */
        function poll() {
          // Poll
          if (pollingEnabled && chart.liveDataURL === url) {
            // We need to stop doing this if the URL has changed
            data.liveDataTimeout = setTimeout(performFetch, updateIntervalMs);
          }
        }

        const success = function (res) {
          const dataRefreshRate = chart?.userOptions?.data?.dataRefreshRate;

          // Data will not update as the user has set a refresh rate of zero
          // Use a cached version for subsequent requests
          const shouldCacheResult =
            domains.includes(window.location.origin) && dataRefreshRate == '' && !window?.everviz?.cachedDataServerData;
          if (shouldCacheResult) {
            if (!window.everviz) window.everviz = {};
            const delimiter = ';';

            const csvStringMap = {
              rowsURL: () => res.map((row) => row.join(delimiter)).join('\n'),
              columnsURL: () => res[0].map((_, i) => `${res[0][i]}${delimiter}${res[1][i]}`).join('\n'),
              csvURL: () => res
            };

            const csvString =
              Object.entries({
                rowsURL: originalOptions?.rowsURL,
                columnsURL: originalOptions?.columnsURL,
                csvURL: originalOptions?.csvURL
              })
                .filter(([, value]) => Boolean(value))
                .map(([key]) => csvStringMap[key]())
                .shift() || '';
            if (this?.options?.csv) {
              this.options.csv = csvString;
            }
            window.everviz.cachedDataServerData = csvString;
          }

          try {
            chart.hideLoading();
          } catch (e) {}

          if (chart && chart.series) {
            done(res);
          }
          poll();
        };

        const request = {
          url: url,
          dataType: tp || 'json',
          success,
          error: function (xhr, text) {
            if (++currentRetries < maxRetries) {
              poll();
            }
            return options.error && options.error(text, xhr);
          }
        };

        if (evervizDataOptions?.symbols) {
          request.type = 'post';
          request.data = evervizDataOptions;
          request.headers = {
            'Content-Type': 'application/json'
          };
        }

        const cachedData = window?.everviz?.cachedDataServerData;

        if (cachedData) {
          setTimeout(function () {
            success(cachedData);
          }, 0);
          return true;
        }
        try {
          if (chart?.showLoading) chart.showLoading();
        } catch (e) {}
        Highcharts.ajax(request);
        return true;
      }

      function isElementInViewport(el) {
        // Special bonus for those using jQuery
        if (typeof jQuery === 'function' && el instanceof jQuery) {
          el = el[0];
        }

        var rect = el.getBoundingClientRect();

        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
      }

      if (pollingEnabled && chart.element && !isElementInViewport(chart.element)) {
        data.liveDataTimeout = setTimeout(performFetch, updateIntervalMs);
        return;
      }

      if (
        !request(
          originalOptions.csvURL,
          function (res) {
            var opts = chart.userOptions;

            var tooltipDecimalValues = false;
            if (opts && opts.tooltip && opts.tooltip.hasOwnProperty('valueDecimals')) {
              tooltipDecimalValues = opts.tooltip.valueDecimals;
            }

            if (opts && opts.everviz && opts.everviz.customer && opts.everviz.customer.data) {
              var parsedCSV = data.evervizGetCSVData(res);
              var newParsedCSV = [];
              var variables = {};
              var extraFields = {};

              parsedCSV.forEach(function (p) {
                newParsedCSV.push([]);
              });

              (opts.everviz.customer.data.fieldsToPlot || []).forEach(function (dataHeader) {
                var index = parsedCSV[0].findIndex(function (p) {
                  return p.indexOf(dataHeader) > -1;
                });
                newParsedCSV.forEach(function (p, i) {
                  p.push(parsedCSV[i][index]);
                });
              });

              (opts.everviz.customer.data.fieldToUseAsVariables || []).forEach(function (name) {
                var index = parsedCSV[0].findIndex(function (p) {
                  return p.indexOf(name) > -1;
                });
                variables['[' + name + ']'] = parsedCSV[1][index];
              });

              if (opts.everviz.customer.data.extraField) {
                var isExtrasArr = Highcharts.isArray(opts.everviz.customer.data.extraField);

                if (isExtrasArr) {
                  opts.everviz.customer.data.extraField.forEach((extraField) => {
                    var index = parsedCSV[0].findIndex(function (p) {
                      return p.indexOf(extraField) > -1;
                    });
                    const camelize = (s) => s.replace(/\../g, (x) => x.toUpperCase()[1]);

                    const key = camelize(extraField.replace(/ /g, '.'));
                    extraFields[key] = [];

                    newParsedCSV.forEach(function (p, i) {
                      extraFields[key].push(parsedCSV[i][index]);
                    });

                    extraFields[key].shift();
                  });
                } else {
                  var index = parsedCSV[0].findIndex(function (p) {
                    return p.indexOf(opts.everviz.customer.data.extraField) > -1;
                  });

                  extraFields.extra = [];

                  newParsedCSV.forEach(function (p, i) {
                    extraFields.extra.push(parsedCSV[i][index]);
                  });

                  extraFields.extra.shift();
                }
              }

              var updateOptions = {
                data: {
                  csv: (newParsedCSV || [])
                    .map(function (cols) {
                      cols = cols.map(function (column) {
                        return !Highcharts.isNumber(column) && Highcharts.isString(column)
                          ? '"' + column + '"'
                          : column;
                      });
                      return cols.join(';');
                    })
                    .join('\n')
                }
              };

              if (Object.keys(variables).length) {
                let title = chart.options.title.text;
                let subtitle = chart.options.subtitle.text;
                let caption = chart.options.caption.text;

                if (title.indexOf('[') > -1) {
                  (Object.keys(variables) || []).forEach((key) => {
                    title = title.replace(key, variables[key]);
                  });
                  updateOptions.title = {
                    text: title
                  };
                }

                if (subtitle.indexOf('[') > -1) {
                  (Object.keys(variables) || []).forEach((key) => {
                    subtitle = subtitle.replace(key, variables[key]);
                  });
                  updateOptions.subtitle = {
                    text: subtitle
                  };
                }

                if (caption.indexOf('[') > -1) {
                  (Object.keys(variables) || []).forEach((key) => {
                    caption = caption.replace(key, variables[key]);
                  });
                  updateOptions.caption = {
                    text: caption
                  };
                }
              }

              if (Object.keys(extraFields).length) {
                var isExtrasArr = Highcharts.isArray(opts.everviz.customer.data.extraField);
                updateOptions.chart = {
                  events: {
                    afterUpdate: function () {
                      if (isExtrasArr) {
                        chart.series.forEach((series) => {
                          series.data.forEach((point, i) => {
                            const extraVal = {};

                            Object.keys(extraFields).forEach((extra) => {
                              //If is a float

                              if (extraFields[extra][i] === null || extraFields[extra][i] === undefined) {
                                extraFields[extra][i] = '';
                              }

                              if (
                                !isNaN(extraFields[extra][i]) &&
                                extraFields[extra][i].toString().indexOf('.') != -1
                              ) {
                                if (tooltipDecimalValues !== false) {
                                  extraVal[extra] = parseFloat(extraFields[extra][i])
                                    .toFixed(tooltipDecimalValues)
                                    .toString()
                                    .replace('.', ',');
                                } else {
                                  extraVal[extra] = extraFields[extra][i];
                                }
                              } else {
                                extraVal[extra] = extraFields[extra][i];
                              }
                              tooltipDecimalValues;
                            });

                            point.update(extraVal);
                          });
                        });
                      } else {
                        chart.series.forEach((series) => {
                          series.data.forEach((point, i) => {
                            point.update({
                              extra: extraFields.extra[i]
                            });
                          });
                        });
                      }
                    }
                  }
                };
              }

              chart.update(updateOptions);
            } else {
              chart.update({
                data: {
                  csv: res
                }
              });
            }
          },
          'text'
        )
      ) {
        if (
          !request(originalOptions.rowsURL, function (res) {
            chart.update({
              data: {
                rows: res
              }
            });
          })
        ) {
          request(originalOptions.columnsURL, function (res) {
            chart.update({
              data: {
                columns: res
              }
            });
          });
        }
      }
    }

    const cachedData = window?.everviz?.cachedDataServerData;
    if (cachedData) {
      // Skip all of this logic if data is cached. Just go on with the rest of the flow in Highcharts
      // Live data seems to reanimate the chart on each "new Highcharts" and we dont want
      // this in the editor, it looks weird.
      if (this?.options?.csv) {
        this.options.csv = cachedData;
      }
      return false;
    }

    performFetch(true);
    return hasURLOption(options);
  });
})(Highcharts);
