import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import apiMiddleware from '../pages/ApiPage/middleware/ApiPage';
import chartEditor from '../pages/ChartEditorPage/middleware/ChartEditor';
import ChartEditorAnnotations from '../pages/ChartEditorPage/middleware/ChartEditorAnnotations';
import chartEditorCustomize from '../pages/ChartEditorPage/middleware/ChartEditorCustomize';
import chartEditorData from '../pages/ChartEditorPage/middleware/ChartEditorData';
import ChartEditorMap from '../pages/ChartEditorPage/middleware/ChartEditorMap';
import chartEditorTemplates from '../pages/ChartEditorPage/middleware/ChartEditorTemplates';
import chartEditorRefinitiv from '../pages/ChartEditorPage/middleware/ChartEditorRefinitiv';
import chartEditorThemes from '../pages/ChartEditorPage/middleware/ChartEditorThemes';
import chartEditorPointMap from '../pages/ChartEditorPage/middleware/ChartEditorPointMap';
import DataGrid from '../pages/ChartEditorPage/middleware/DataGrid';
import companyThemeEditorMiddleware from '../pages/CompanyThemeEditorPage/middleware/themeEditorPage';
import customiseEditorMiddleware from '../pages/CustomiseEditorPage/middleware/CustomiseEditorPage';
import layoutEditor from '../pages/LayoutEditorPage/middleware/layoutEditor';
import chartsMiddleware from '../pages/ProjectsPage/middleware/ProjectsPage';
import rolesMiddleware from '../pages/RolesPage/middleware/RolesPage';
import settingsMiddleware from '../pages/SettingsPage/middleware/SettingsPage';
import showChartMiddleware from '../pages/ShowChartPage/middleware/ShowChartPage';
import plansMiddleware from '../pages/SubscriptionPage/middleware/PlansPage';
import tableEditor from '../pages/TableEditorPage/middleware/tableEditor';
import tableEditorData from '../pages/TableEditorPage/middleware/tableEditorData';
import tableEditorInlineStyle from '../pages/TableEditorPage/middleware/tableEditorInlineStyle';
import tableEditorThemes from '../pages/TableEditorPage/middleware/tableEditorThemes';
import teamInvitationsMiddleware from '../pages/TeamPage/middleware/TeamInvitationsMiddleware';
import teamMembersMiddleware from '../pages/TeamPage/middleware/TeamMembersPageMiddleware';
import teamWebhooksMiddleware from '../pages/TeamWebhooksPage/middleware/TeamWebhooksPage';
import themesMiddleware from '../pages/ThemesPage/middleware/ThemesPage';
import themeEditor from './../pages/ThemeEditorPage/middleware/ThemeEditor';
import profileMiddleware from './Profile';
import projectEditor from './editor/ProjectEditor';
import redeemInviteMiddleware from 'pages/RedeemInvitePage/middleware/RedeemInvitePage';
import PackagesPageMiddleware from 'pages/PackagesPage/middleware/PackagesPage';
import LocationMapCustomize from '../pages/ChartEditorPage/middleware/LocationMap/LocationMapCustomize';
import LocationMapViewState from '../pages/ChartEditorPage/middleware/LocationMap/LocationMapViewState';
import LocationMapAnimation from '../pages/ChartEditorPage/middleware/LocationMap/LocationMapAnimation';
import LocationMapMarker from '../pages/ChartEditorPage/middleware/LocationMap/LocationMapMarker';

const sagaMiddleware = createSagaMiddleware({
  onError: Sentry.captureException
});

export function init() {
  sagaMiddleware.run(profileMiddleware);
  sagaMiddleware.run(chartsMiddleware);
  sagaMiddleware.run(customiseEditorMiddleware);
  sagaMiddleware.run(settingsMiddleware);
  sagaMiddleware.run(apiMiddleware);
  sagaMiddleware.run(teamMembersMiddleware);
  sagaMiddleware.run(rolesMiddleware);
  sagaMiddleware.run(teamWebhooksMiddleware);
  sagaMiddleware.run(themeEditor);
  sagaMiddleware.run(teamInvitationsMiddleware);
  sagaMiddleware.run(layoutEditor);
  sagaMiddleware.run(tableEditor);
  sagaMiddleware.run(tableEditorData);
  sagaMiddleware.run(tableEditorThemes);
  sagaMiddleware.run(tableEditorInlineStyle);
  sagaMiddleware.run(themesMiddleware);
  sagaMiddleware.run(plansMiddleware);
  sagaMiddleware.run(showChartMiddleware);
  sagaMiddleware.run(chartEditor);
  sagaMiddleware.run(chartEditorData);
  sagaMiddleware.run(chartEditorTemplates);
  sagaMiddleware.run(chartEditorThemes);
  sagaMiddleware.run(chartEditorCustomize);
  sagaMiddleware.run(chartEditorRefinitiv);
  sagaMiddleware.run(ChartEditorMap);
  sagaMiddleware.run(chartEditorPointMap);
  sagaMiddleware.run(companyThemeEditorMiddleware);
  sagaMiddleware.run(ChartEditorAnnotations);
  sagaMiddleware.run(DataGrid);
  sagaMiddleware.run(projectEditor);
  sagaMiddleware.run(redeemInviteMiddleware);
  sagaMiddleware.run(PackagesPageMiddleware);
  sagaMiddleware.run(LocationMapCustomize);
  sagaMiddleware.run(LocationMapViewState);
  sagaMiddleware.run(LocationMapAnimation);
  sagaMiddleware.run(LocationMapMarker);
}

export default sagaMiddleware;
