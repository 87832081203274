import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LngLat, Map, PaddingOptions } from '@visual-elements/maplibre-gl';
import { startAppListening } from '../../listenerMiddleware';
import { RootState } from '../../store';
import { storeLocationMapContainerRef } from './instanceReducer';
import { revertLocationMapState } from '../../actions/locationMap';
import { setSearchResult } from './searchReducer';
import { FeatureCollection } from '@turf/helpers';
import bbox from '@turf/bbox';
import { createAppAsyncThunk } from 'redux/helpers/utils';

type ErrorTypes = 'MOVE' | 'OUTSIDE_VIEW' | 'NONE';
export type ViewState = {
  viewIsLocked: boolean;
  viewBoxAspectRatio: number;
  viewBoxResolution: { height: number; width: number } | undefined;
  error: ErrorTypes;
};

const initialState: ViewState = {
  viewIsLocked: false,
  viewBoxAspectRatio: 1,
  viewBoxResolution: undefined,
  error: 'NONE'
};

const viewStateSlice = createSlice({
  name: 'viewState',
  initialState,
  extraReducers: (builder) => builder.addCase(revertLocationMapState, () => initialState),
  reducers: {
    setViewIsLocked(state, action: PayloadAction<boolean>) {
      state.viewIsLocked = action.payload;
    },
    setViewBoxAspectRatio(state, action: PayloadAction<number>) {
      state.viewBoxAspectRatio = action.payload;
    },
    setViewBoxResolution(state, action: PayloadAction<{ height: number; width: number }>) {
      state.viewBoxResolution = action.payload;
    },
    setMapViewError(state, action: PayloadAction<ErrorTypes>) {
      state.error = action.payload;
    }
  }
});

export const selectViewIsLocked = (state: RootState) => state.viewState.viewIsLocked;
export const selectViewBoxAspectRatio = (state: RootState) => state.viewState.viewBoxAspectRatio;
export const selectNavigationLockError = (state: RootState) => state.viewState.error;

export const { setViewIsLocked, setMapViewError, setViewBoxAspectRatio } = viewStateSlice.actions;

let clearErrorHandlers: () => void = () => null;

startAppListening({
  actionCreator: storeLocationMapContainerRef,
  effect: (action, listenerApi) => {
    const rootState = listenerApi.getState();
    if (!rootState.locationMapInstance.mapDefined) return;
    const map = rootState.locationMapInstance.locationMap.getMapEngine();
    enabledOrDisableNavHandlers(map, rootState.viewState.viewIsLocked);
    if (rootState.viewState.viewIsLocked) {
      clearErrorHandlers();
      clearErrorHandlers = addErrorHandlers(map, (error: ErrorTypes) => listenerApi.dispatch(setMapViewError(error)));
    }
  }
});

startAppListening({
  actionCreator: setViewIsLocked,
  effect: (action, listenerApi) => {
    const rootState = listenerApi.getState();
    if (!rootState.locationMapInstance.mapDefined) {
      return;
    }

    const map = rootState.locationMapInstance.locationMap.getMapEngine();
    clearErrorHandlers();
    enabledOrDisableNavHandlers(map, action.payload);

    if (action.payload) {
      clearErrorHandlers = addErrorHandlers(map, (error: ErrorTypes) => listenerApi.dispatch(setMapViewError(error)));
    }
  }
});

startAppListening({
  actionCreator: setSearchResult,
  effect: (action, listenerApi) => {
    const rootState = listenerApi.getState();
    if (!rootState.locationMapInstance.mapDefined) {
      throw new Error('Location map should be defined if search result is set');
    }
    const map = rootState.locationMapInstance.locationMap.getMapEngine();

    if (
      rootState.viewState.viewIsLocked &&
      !map.getBounds().contains(new LngLat(action.payload.result.lon, action.payload.result.lat))
    ) {
      listenerApi.dispatch(setMapViewError('OUTSIDE_VIEW'));
    }
  }
});

let mouseDown = false;

function addErrorHandlers(map: Map, setError: (error: ErrorTypes) => void) {
  const handleOnMouseDown = (ev: MouseEvent) => {
    if (ev.button === 0) {
      mouseDown = true;
    }
  };
  const handleOnMouseMove = (ev: MouseEvent) => {
    if (ev.button === 0 && mouseDown) {
      mouseDown = false;
      setError('MOVE');
    }
  };
  const handleOnMouseUp = (ev: MouseEvent) => {
    if (ev.button === 0) {
      mouseDown = false;
    }
  };
  const handleOnWheel = () => {
    setError('MOVE');
  };
  const canvasContainer = map.getCanvasContainer();
  if (canvasContainer) {
    canvasContainer.addEventListener('mousedown', handleOnMouseDown, { passive: true });
    canvasContainer.addEventListener('mousemove', handleOnMouseMove, { passive: true });
    canvasContainer.addEventListener('mouseup', handleOnMouseUp, { passive: true });
    canvasContainer.addEventListener('wheel', handleOnWheel, { passive: true });
  }

  return () => {
    canvasContainer.removeEventListener('mousedown', handleOnMouseDown);
    canvasContainer.removeEventListener('mousemove', handleOnMouseMove);
    canvasContainer.removeEventListener('mouseup', handleOnMouseUp);
    canvasContainer.removeEventListener('wheel', handleOnWheel);
  };
}

function enabledOrDisableNavHandlers(map: Map, disable: boolean) {
  if (disable) {
    map.scrollZoom.disable();
    map.boxZoom.disable();
    map.dragPan.disable();
    map.keyboard.disable();
    map.doubleClickZoom.disable();
    map.touchZoomRotate.disable();
    map.dragRotate.disable();
    map.touchPitch.disable();
    map.touchZoomRotate.disable();
    map.getCanvas().style.cursor = 'context-menu';
  } else {
    map.scrollZoom.enable();
    map.boxZoom.enable();
    map.dragPan.enable();
    map.keyboard.enable();
    map.doubleClickZoom.enable();
    map.touchZoomRotate.enable();
    map.dragRotate.enable();
    map.touchPitch.enable();
    map.touchZoomRotate.enable();
    map.getCanvas().style.cursor = 'grab';
  }
}

/**
 * This function takes all the current features on the map, only markers currently, and fits the map to those features.
 * For this to work we have to take a few steps to achieve it.
 *  1. Apply padding on the map equal to the difference between the viewbox and the map container size
 *  2. Fit map to bounding box
 *  2. Get all the dom elements inside the map and calculate how far outside the map they are. Add that as padding.
 *  4. Fit map to bounding box and additional padding to take into account dom elements
 *  5. We need to reset the padding of the map. To accomplish that we have to find the new center of the map
 *     based on the change in padding. We're using some maplibre functions to get the new center here.
 *  6. Return to the first position
 *  7. Animate to the new location using the stored viewstate.
 */
export const fitMapToFeatures = createAppAsyncThunk('locationMap/fitMapToFeatures', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const { aggregatedOptions } = state.projectConfig;
  const { locationMap, mapDefined } = state.locationMapInstance;
  const { viewIsLocked } = state.viewState;

  if (!mapDefined) {
    console.log("Can't fit map to features when map is not loaded");
    return;
  }
  if (viewIsLocked) throw new Error("Can't fit map to features if the map view is locked");
  const map = locationMap.getMapEngine();
  const featureCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };

  for (const marker of aggregatedOptions.markers) {
    if (marker.data.type === 'static') {
      // There is a typing issue between turf and the GeoJson typing library that we use
      // Should look into it, but not urgent
      featureCollection.features.push(marker.data.content);
    }
  }

  if (featureCollection.features.length === 0) return;
  const featureBbox = bbox(featureCollection);

  const oldViewState = {
    center: map.getCenter(),
    bearing: map.getBearing(),
    zoom: map.getZoom(),
    pitch: map.getPitch()
  };

  const container = map.getContainer();
  const containerRect = container.getBoundingClientRect();
  const viewBox = container.querySelector('#viewbox');
  if (!viewBox) throw new Error('Viewbox should be defined');
  const viewBoxRect = viewBox.getBoundingClientRect();

  const viewBoxPadding = {
    left: viewBoxRect.left - containerRect.left,
    bottom: containerRect.bottom - viewBoxRect.bottom,
    right: containerRect.right - viewBoxRect.right,
    top: viewBoxRect.top - containerRect.top
  };
  map.setPadding(viewBoxPadding, { initiatedProgrammatically: true });

  map.fitBounds(
    // Same typing issue here
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    featureBbox,
    {
      bearing: oldViewState.bearing,
      animate: false,
      maxZoom: featureCollection.features.length > 1 ? 24 : 11
    },
    { initiatedProgrammatically: true }
  );

  const elements = locationMap.getElements();
  const padding: PaddingOptions = {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  };

  if (elements) {
    elements.forEach((element) => {
      const targetRect = element.getBoundingClientRect();
      if (viewBoxRect.left - targetRect.left > padding.left) {
        padding.left = viewBoxRect.left - targetRect.left;
      }
      if (viewBoxRect.top - targetRect.top > padding.top) {
        padding.top = viewBoxRect.top - targetRect.top;
      }
      if (targetRect.right - viewBoxRect.right > padding.right) {
        padding.right = targetRect.right - viewBoxRect.right;
      }
      if (targetRect.bottom - viewBoxRect.bottom > padding.bottom) {
        padding.bottom = targetRect.bottom - viewBoxRect.bottom;
      }
    });
  }

  const defaultPadding = 24;
  map.setPadding(
    {
      left: viewBoxPadding.left + padding.left + defaultPadding,
      right: viewBoxPadding.right + padding.right + defaultPadding,
      top: viewBoxPadding.top + padding.top + defaultPadding,
      bottom: viewBoxPadding.bottom + padding.bottom + defaultPadding
    },
    { initiatedProgrammatically: true }
  );
  map.fitBounds(
    // Same typing issue here
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    featureBbox,
    {
      bearing: oldViewState.bearing,
      animate: false,
      maxZoom: featureCollection.features.length > 1 ? 24 : 11
    },
    { initiatedProgrammatically: true }
  );

  // We need to offset the center point with the difference in padding to revert padding to 0
  const newCenter = map.transform._edgeInsets.getCenter(map.transform.width, map.transform.height);
  newCenter.x += (padding.right - padding.left) / 2;
  newCenter.y += (padding.bottom - padding.top) / 2;
  const center = map.unproject(newCenter);
  map.jumpTo(
    { center: center, padding: { bottom: 0, left: 0, right: 0, top: 0 } },
    { initiatedProgrammatically: true }
  );

  const newViewState = {
    center: map.getCenter(),
    bearing: map.getBearing(),
    zoom: map.getZoom(),
    pitch: map.getPitch()
  };
  map.jumpTo(
    {
      ...oldViewState
    },
    { initiatedProgrammatically: true }
  );

  map.flyTo({
    ...newViewState,
    animate: true,
    essential: true,
    duration: 2000
  });
});

export default viewStateSlice.reducer;
